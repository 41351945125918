import React from 'react';

/* RAN SVG CODE THROUGH https://transform.tools/ (recommended by material-UI) */

const Availabilities = () => (
  <svg width="80%" viewBox="0 0 940 640">
    <defs>
      <rect id="b" x={4} y={2} width={929} height={185} rx={8} />
      <filter
        x="-0.8%"
        y="-2.7%"
        width="101.5%"
        height="107.6%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="d" x={5} y={221} width={929} height={185} rx={8} />
      <filter
        x="-0.8%"
        y="-2.7%"
        width="101.5%"
        height="107.6%"
        filterUnits="objectBoundingBox"
        id="c"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="f" x={5} y={440} width={929} height={185} rx={8} />
      <filter
        x="-0.8%"
        y="-2.7%"
        width="101.5%"
        height="107.6%"
        filterUnits="objectBoundingBox"
        id="e"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <filter
        x="-7.1%"
        y="-7.1%"
        width="114.2%"
        height="114.2%"
        filterUnits="objectBoundingBox"
        id="g"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="-2.2033937e-15%" id="h">
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <filter
        x="-7.1%"
        y="-7.1%"
        width="114.2%"
        height="114.2%"
        filterUnits="objectBoundingBox"
        id="i"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0.0043144361%" id="j">
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <filter
        x="-7.1%"
        y="-7.1%"
        width="114.2%"
        height="114.2%"
        filterUnits="objectBoundingBox"
        id="k"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <rect id="m" x={320} y={77} width={535} height={34} rx={8} />
      <filter
        x="-1.3%"
        y="-14.7%"
        width="102.6%"
        height="141.2%"
        filterUnits="objectBoundingBox"
        id="l"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="o" x={304} y={516} width={457} height={35} rx={8} />
      <filter
        x="-1.5%"
        y="-14.3%"
        width="103.1%"
        height="140.0%"
        filterUnits="objectBoundingBox"
        id="n"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="q" x={678} y={296} width={227} height={35} rx={8} />
      <filter
        x="-3.1%"
        y="-14.3%"
        width="106.2%"
        height="140.0%"
        filterUnits="objectBoundingBox"
        id="p"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
      <rect id="s" x={242} y={296} width={314} height={35} rx={8} />
      <filter
        x="-2.2%"
        y="-14.3%"
        width="104.5%"
        height="140.0%"
        filterUnits="objectBoundingBox"
        id="r"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#a)" href="#b" />
      <use fill="#EFECEC" href="#b" />
      <use fill="#000" filter="url(#c)" href="#d" />
      <use fill="#EFECEC" href="#d" />
      <use fill="#000" filter="url(#e)" href="#f" />
      <use fill="#EFECEC" href="#f" />
      <g filter="url(#g)" transform="translate(12 229)" fillRule="nonzero">
        <circle cx={84.5} cy={84.5} r={84.5} opacity={0.5} fill="url(#h)" />
        <circle fill="#F5F5F5" cx={84.5} cy={83.6058201} r={82.7116402} />
        <path
          d="M52.058 84.053s-9.381 46.2-14.502 43.64c0 0 49.386 42.642 92.994 0 0 0-7.681-32.557-18.77-43.64H52.059z"
          fill="#333"
        />
        <path
          d="M85.07 166.317a82.207 82.207 0 0045.48-13.641c-3.048-16.697-14.652-17.655-14.652-17.655H51.923s-10.94.9-14.367 16.266a82.22 82.22 0 0047.514 15.03z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={84.0529101} cy={82.2645503} r={36.6613757} />
        <path
          d="M70.64 115.35h25.931v20.36c0 7.019-5.79 12.712-12.94 12.724-7.158 0-12.961-5.697-12.961-12.723v-20.362h-.03z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M73.09 114.455h21.003c1.369 0 2.478 1.088 2.478 2.43v17.931c0 7.019-5.796 12.712-12.954 12.724-7.167 0-12.977-5.697-12.977-12.724v-17.938c.004-1.328 1.095-2.407 2.45-2.423z"
          fill="#BE7C5E"
        />
        <path
          d="M70.64 124.658a38.023 38.023 0 0025.931.073v-3.123h-25.93v3.05z"
          fill="#000"
          opacity={0.1}
        />
        <circle fill="#BE7C5E" cx={84.0529101} cy={89.4179894} r={36.6613757} />
        <path
          d="M48.286 76.005h70.64s-6.033-28.564-32.735-26.741C59.49 51.086 48.286 76.005 48.286 76.005z"
          fill="#333"
        />
        <ellipse fill="#BE7C5E" cx={47.8386243} cy={88.5238095} rx={3.12962963} ry={6.25925926} />
        <ellipse fill="#BE7C5E" cx={119.373016} cy={88.5238095} rx={3.12962963} ry={6.25925926} />
        <path
          d="M48.286 76.9h70.64s-6.033-28.564-32.735-26.742C59.49 51.98 48.286 76.899 48.286 76.899z"
          fill="#000"
          opacity={0.1}
        />
      </g>
      <g filter="url(#i)" transform="translate(12 448.84)" fillRule="nonzero">
        <circle cx={84.5} cy={84.5} r={84.5} opacity={0.5} fill="url(#j)" />
        <circle fill="#F5F5F5" cx={84.6604107} cy={83.9604366} r={82.7136077} />
        <path
          d="M84.733 167.002a82.329 82.329 0 0045.462-13.606c-3.048-16.653-14.648-17.608-14.648-17.608H51.565s-10.937.897-14.364 16.223a82.336 82.336 0 0047.532 14.991z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={83.8583571} cy={82.2177927} r={39.9276901} />
        <path
          d="M70.974 115.474H96.56v20.467c0 7.063-5.726 12.789-12.79 12.789a12.79 12.79 0 01-12.788-12.79v-20.466h-.008z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M73.417 114.526h20.7a2.443 2.443 0 012.443 2.443v18.039c0 7.063-5.726 12.789-12.79 12.789a12.79 12.79 0 01-12.796-12.79v-18.046a2.443 2.443 0 012.443-2.442v.007z"
          fill="#FDB797"
        />
        <path
          d="M71.025 125.135a36.822 36.822 0 0025.586.073v-3.165H71.025v3.092z"
          fill="#000"
          opacity={0.1}
        />
        <circle fill="#FDB797" cx={83.8583571} cy={90.1799983} r={36.6684356} />
        <path
          d="M57.748 56.822s15.45 31.353 59.06 13.124l-10.15-15.917-17.987-6.46-30.923 9.253z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M57.748 56.362s15.45 31.354 59.06 13.125l-10.15-15.917-17.987-6.46-30.923 9.252z"
          fill="#333"
        />
        <path
          d="M57.61 54.233a21.182 21.182 0 015.25-8.538c7.21-7.13 19.037-8.633 25.103-16.77a4.747 4.747 0 01-2.187 6.693c5.833-.036 12.548-.546 15.83-5.33a10.871 10.871 0 01-1.83 11.477c5.147.233 10.645 3.726 10.98 8.874.22 3.427-1.925 6.671-4.746 8.64-2.822 1.969-6.227 2.866-9.581 3.61-9.785 2.172-45.207 11.22-38.82-8.656z"
          fill="#333"
        />
        <ellipse fill="#FDB797" cx={47.1753387} cy={90.1435413} rx={3.4123738} ry={6.39455518} />
        <ellipse fill="#FDB797" cx={120.519501} cy={90.1435413} rx={3.4123738} ry={6.39455518} />
      </g>
      <g filter="url(#k)" transform="translate(12 10)" fillRule="nonzero">
        <circle cx={84.5} cy={84.5} r={84.5} opacity={0.5} fill="url(#j)" />
        <circle fill="#F5F5F5" cx={84.5} cy={84.5} r={82.5} />
        <path
          d="M130 92.222C130 122.876 110.297 157 85.99 157 61.681 157 42 122.876 42 92.222 42 61.57 61.703 46 86.01 46 110.319 46 130 61.576 130 92.222z"
          fill="#FDD835"
        />
        <path
          d="M83.514 167A82.791 82.791 0 00129 153.488C125.951 136.948 114.346 136 114.346 136H50.37s-10.94.89-14.369 16.112A82.781 82.781 0 0083.514 167z"
          fill="#991A5F"
        />
        <path
          d="M71 116h26v20.31c0 7.008-5.819 12.69-12.996 12.69-7.178 0-12.997-5.682-12.997-12.69V116H71z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M73.475 115h21.042c1.371 0 2.483 1.085 2.483 2.424v17.885C97 142.32 91.18 148 84 148s-13-5.682-13-12.69v-17.886c0-1.336 1.107-2.42 2.475-2.424z"
          fill="#FDB797"
        />
        <path d="M71 124.842a40.849 40.849 0 0026 .068V122H71v2.842z" fill="#000" opacity={0.1} />
        <circle fill="#FDB797" cx={83.5} cy={90.5} r={36.5} />
        <ellipse fill="#FDB797" cx={48} cy={88} rx={3} ry={6} />
        <ellipse fill="#FDB797" cx={119} cy={88} rx={3} ry={6} />
        <path
          d="M51 70.438s20.625 28.185 67.926 18.558c0 0 5.28-6.418-7.574-26.12C98.496 43.174 62.702 46.383 51 70.438z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M51 69.438s20.625 28.185 67.926 18.558c0 0 5.28-6.418-7.574-26.12C98.496 42.174 62.702 45.383 51 69.438z"
          fill="#FDD835"
        />
      </g>
      <use fill="#000" filter="url(#l)" href="#m" />
      <use fill="#88D3AF" href="#m" />
      <g>
        <use fill="#000" filter="url(#n)" href="#o" />
        <use fill="#88D3AF" href="#o" />
      </g>
      <g>
        <use fill="#000" filter="url(#p)" href="#q" />
        <use fill="#88D3AF" href="#q" />
      </g>
      <g>
        <use fill="#000" filter="url(#r)" href="#s" />
        <use fill="#88D3AF" href="#s" />
      </g>
    </g>
  </svg>
);

export default Availabilities;

/*
OLD CODE:

<svg width="500px" height="360px" viewBox="0 0 1000 600" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <rect id="path-1" x="4" y="2" width="929" height="185" rx="8"></rect>
        <filter x="-0.8%" y="-2.7%" width="101.5%" height="107.6%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="5" y="221" width="929" height="185" rx="8"></rect>
        <filter x="-0.8%" y="-2.7%" width="101.5%" height="107.6%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-5" x="5" y="440" width="929" height="185" rx="8"></rect>
        <filter x="-0.8%" y="-2.7%" width="101.5%" height="107.6%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <filter x="-7.1%" y="-7.1%" width="114.2%" height="114.2%" filterUnits="objectBoundingBox" id="filter-7">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="-2.2033937e-15%" id="linearGradient-8">
            <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
            <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
            <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
        </linearGradient>
        <filter x="-7.1%" y="-7.1%" width="114.2%" height="114.2%" filterUnits="objectBoundingBox" id="filter-9">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0.0043144361%" id="linearGradient-10">
            <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
            <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
            <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
        </linearGradient>
        <filter x="-7.1%" y="-7.1%" width="114.2%" height="114.2%" filterUnits="objectBoundingBox" id="filter-11">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
        <rect id="path-12" x="320" y="77" width="535" height="34" rx="8"></rect>
        <filter x="-1.3%" y="-14.7%" width="102.6%" height="141.2%" filterUnits="objectBoundingBox" id="filter-13">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-14" x="304" y="516" width="457" height="35" rx="8"></rect>
        <filter x="-1.5%" y="-14.3%" width="103.1%" height="140.0%" filterUnits="objectBoundingBox" id="filter-15">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-16" x="678" y="296" width="227" height="35" rx="8"></rect>
        <filter x="-3.1%" y="-14.3%" width="106.2%" height="140.0%" filterUnits="objectBoundingBox" id="filter-17">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-18" x="242" y="296" width="314" height="35" rx="8"></rect>
        <filter x="-2.2%" y="-14.3%" width="104.5%" height="140.0%" filterUnits="objectBoundingBox" id="filter-19">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)"  href="#path-1"></use>
            <use fill="#EFECEC" fill-rule="evenodd"  href="#path-1"></use>
        </g>
        <g id="Rectangle-Copy">
            <use fill="black" fill-opacity="1" filter="url(#filter-4)"  href="#path-3"></use>
            <use fill="#EFECEC" fill-rule="evenodd"  href="#path-3"></use>
        </g>
        <g id="Rectangle-Copy-2">
            <use fill="black" fill-opacity="1" filter="url(#filter-6)"  href="#path-5"></use>
            <use fill="#EFECEC" fill-rule="evenodd"  href="#path-5"></use>
        </g>
        <g id="Group-4" filter="url(#filter-7)" transform="translate(12.000000, 229.000000)" fill-rule="nonzero">
            <g id="Group" opacity="0.5" fill="url(#linearGradient-8)">
                <circle id="Oval" cx="84.5" cy="84.5" r="84.5"></circle>
            </g>
            <circle id="Oval" fill="#F5F5F5" cx="84.5" cy="83.6058201" r="82.7116402"></circle>
            <path d="M52.0577113,84.0529101 C52.0577113,84.0529101 42.6765381,130.252995 37.5555556,127.693616 C37.5555556,127.693616 86.9416694,170.335361 130.550265,127.693616 C130.550265,127.693616 122.868791,95.1362639 111.780623,84.0529101 L52.0577113,84.0529101 Z" id="Path" fill="#333333"></path>
            <path d="M85.0703018,166.31746 C101.237523,166.344012 117.054912,161.599708 130.550265,152.675984 C127.501736,135.978838 115.898365,135.021164 115.898365,135.021164 L51.9230229,135.021164 C51.9230229,135.021164 40.983327,135.920354 37.5555556,151.286992 C51.4656375,161.094642 68.0637306,166.345122 85.0703018,166.31746 Z" id="Path" fill="#991A5F"></path>
            <circle id="Oval" fill="#333333" cx="84.0529101" cy="82.2645503" r="36.6613757"></circle>
            <path d="M70.6402116,115.349206 L96.5714286,115.349206 L96.5714286,135.710669 C96.5714286,142.729005 90.7816926,148.421858 83.6316848,148.433862 L83.6316848,148.433862 C76.4730177,148.433862 70.6697713,142.737495 70.6697713,135.710669 L70.6697713,115.349206 L70.6402116,115.349206 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <path d="M73.0890655,114.455026 L94.0929813,114.455026 C95.4617899,114.455026 96.5714286,115.542987 96.5714286,116.885055 L96.5714286,134.816489 C96.5714286,141.834825 90.7750852,147.527679 83.6169176,147.539683 L83.6169176,147.539683 C76.4500808,147.539683 70.6402116,141.843315 70.6402116,134.816489 L70.6402116,116.877801 C70.6441583,115.54981 71.7347048,114.470877 73.0890655,114.455026 Z" id="Path" fill="#BE7C5E"></path>
            <path d="M70.6402116,124.658292 C78.9972401,127.720174 88.1967335,127.745874 96.5714286,124.730734 L96.5714286,121.608466 L70.6402116,121.608466 L70.6402116,124.658292 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <circle id="Oval" fill="#BE7C5E" cx="84.0529101" cy="89.4179894" r="36.6613757"></circle>
            <path d="M48.2857143,76.005291 L118.925926,76.005291 C118.925926,76.005291 112.893149,47.4413763 86.191296,49.2635062 C59.4894434,51.0856361 48.2857143,76.005291 48.2857143,76.005291 Z" id="Path" fill="#333333"></path>
            <ellipse id="Oval" fill="#BE7C5E" cx="47.8386243" cy="88.5238095" rx="3.12962963" ry="6.25925926"></ellipse>
            <ellipse id="Oval" fill="#BE7C5E" cx="119.373016" cy="88.5238095" rx="3.12962963" ry="6.25925926"></ellipse>
            <path d="M48.2857143,76.8994709 L118.925926,76.8994709 C118.925926,76.8994709 112.893149,48.3355562 86.191296,50.1576861 C59.4894434,51.979816 48.2857143,76.8994709 48.2857143,76.8994709 Z" id="Path" fill="#000000" opacity="0.1"></path>
        </g>
        <g id="Group-6" filter="url(#filter-9)" transform="translate(12.000000, 448.840000)" fill-rule="nonzero">
            <g id="Group" opacity="0.5" fill="url(#linearGradient-10)">
                <circle id="Oval" cx="84.5" cy="84.5" r="84.5"></circle>
            </g>
            <circle id="Oval" fill="#F5F5F5" cx="84.6604107" cy="83.9604366" r="82.7136077"></circle>
            <path d="M84.7333247,167.002319 C100.894221,167.027319 116.705112,162.295463 130.195185,153.39641 C127.147381,136.74286 115.546768,135.787687 115.546768,135.787687 L51.5647597,135.787687 C51.5647597,135.787687 40.6276642,136.684528 37.2007076,152.011045 C51.1156193,161.798833 67.7208461,167.035886 84.7333247,167.002319 L84.7333247,167.002319 Z" id="Path" fill="#991A5F"></path>
            <circle id="Oval" fill="#333333" cx="83.8583571" cy="82.2177927" r="39.9276901"></circle>
            <path d="M70.9744585,115.473855 L96.5599707,115.473855 L96.5599707,135.940806 C96.5599707,143.004037 90.8340909,148.729916 83.7708603,148.729916 L83.7708603,148.729916 C80.3789773,148.729916 77.1260171,147.382496 74.7275936,144.984073 C72.3291702,142.585649 70.9817499,139.332689 70.9817499,135.940806 L70.9817499,115.473855 L70.9744585,115.473855 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <path d="M73.4170765,114.525973 L94.1173527,114.525973 C95.4663733,114.525973 96.5599707,115.61957 96.5599707,116.968591 L96.5599707,135.007507 C96.5599707,142.070738 90.8340909,147.79662 83.7708603,147.79662 L83.7708603,147.79662 C80.3777142,147.798552 77.1228804,146.451986 74.7228798,144.053353 C72.3228791,141.65472 70.9744585,138.400654 70.9744585,135.007507 L70.9744585,116.9613 C70.9744585,115.612279 72.0680559,114.518682 73.4170765,114.518682 L73.4170765,114.525973 Z" id="Path" fill="#FDB797"></path>
            <path d="M71.0254983,125.134956 C79.2711141,128.216768 88.3479635,128.242635 96.6110104,125.20787 L96.6110104,122.043403 L71.0254983,122.043403 L71.0254983,125.134956 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <circle id="Oval" fill="#FDB797" cx="83.8583571" cy="90.1799983" r="36.6684356"></circle>
            <path d="M57.7478644,56.8218569 C57.7478644,56.8218569 73.1983346,88.1748641 116.80818,69.9463716 L106.658556,54.0292519 L88.6706791,47.5690741 L57.7478644,56.8218569 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <path d="M57.7478644,56.3624989 C57.7478644,56.3624989 73.1983346,87.7155061 116.80818,69.4870135 L106.658556,53.5698939 L88.6706791,47.1097161 L57.7478644,56.3624989 Z" id="Path" fill="#333333"></path>
            <path d="M57.6093278,54.233411 C58.6509859,51.0083255 60.4513221,48.0802787 62.8591337,45.6951851 C70.0703253,38.5641988 81.8969713,37.062171 87.9634136,28.924972 C88.629964,30.0895595 88.772461,31.481857 88.3556418,32.7573238 C87.9388226,34.0327905 87.001634,35.0722496 85.7759945,35.6184744 C91.6091121,35.5820174 98.3244887,35.0716196 101.605617,30.2884632 C103.263113,34.1464363 102.550684,38.6140158 99.7754767,41.7651221 C104.923203,41.9984468 110.420916,45.491026 110.756321,50.6387523 C110.975063,54.0657089 108.831392,57.3103805 106.009621,59.2790577 C103.187851,61.2477349 99.7827681,62.1445768 96.4287255,62.8882992 C86.6436707,65.0611356 51.222064,74.1097593 57.6093278,54.233411 Z" id="Path" fill="#333333"></path>
            <ellipse id="Oval" fill="#FDB797" cx="47.1753387" cy="90.1435413" rx="3.4123738" ry="6.39455518"></ellipse>
            <ellipse id="Oval" fill="#FDB797" cx="120.519501" cy="90.1435413" rx="3.4123738" ry="6.39455518"></ellipse>
        </g>
        <g id="Group-9" filter="url(#filter-11)" transform="translate(12.000000, 10.000000)" fill-rule="nonzero">
            <g id="Group" opacity="0.5" fill="url(#linearGradient-10)">
                <circle id="Oval" cx="84.5" cy="84.5" r="84.5"></circle>
            </g>
            <circle id="Oval" fill="#F5F5F5" cx="84.5" cy="84.5" r="82.5"></circle>
            <path d="M130,92.2224866 C130,122.876066 110.296674,157 85.9891581,157 C61.6816427,157 42,122.876066 42,92.2224866 C42,61.5689074 61.7033265,46 86.0108419,46 C110.318357,46 130,61.5762443 130,92.2224866 Z" id="Path" fill="#FDD835"></path>
            <path d="M83.5138824,167 C99.6832919,167.026301 115.502821,162.326913 129,153.487674 C125.951059,136.948607 114.346118,136 114.346118,136 L50.3694118,136 C50.3694118,136 39.4282353,136.890677 36,152.111832 C49.9099294,161.825202 66.5074982,167.025904 83.5138824,167 L83.5138824,167 Z" id="Path" fill="#991A5F"></path>
            <path d="M71,116 L97,116 L97,136.309362 C97,143.318208 91.1813604,149 84.0037048,149 L84.0037048,149 C76.8260491,149 71.0074095,143.318208 71.0074095,136.309362 L71.0074095,116 L71,116 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <path d="M73.4754846,115 L94.5171038,115 C95.8883695,115 97,116.085177 97,117.423811 L97,135.309362 C97,142.318208 91.1797017,148 84,148 L84,148 C76.8202983,148 71,142.318208 71,135.309362 L71,117.423811 C71,116.087997 72.1071142,115.003988 73.4754846,115 Z" id="Path" fill="#FDB797"></path>
            <path d="M71,124.842294 C79.3791956,127.695823 88.6030909,127.719774 97,124.909807 L97,122 L71,122 L71,124.842294 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <circle id="Oval" fill="#FDB797" cx="83.5" cy="90.5" r="36.5"></circle>
            <ellipse id="Oval" fill="#FDB797" cx="48" cy="88" rx="3" ry="6"></ellipse>
            <ellipse id="Oval" fill="#FDB797" cx="119" cy="88" rx="3" ry="6"></ellipse>
            <path d="M51,70.4383421 C51,70.4383421 71.6250355,98.6226756 118.925504,88.9961068 C118.925504,88.9961068 124.206906,82.5783943 111.351625,62.8761616 C98.4963444,43.173929 62.7017876,46.3827853 51,70.4383421 Z" id="Path" fill="#000000" opacity="0.1"></path>
            <path d="M51,69.4383421 C51,69.4383421 71.6250355,97.6226756 118.925504,87.9961068 C118.925504,87.9961068 124.206906,81.5783943 111.351625,61.8761616 C98.4963444,42.173929 62.7017876,45.3827853 51,69.4383421 Z" id="Path" fill="#FDD835"></path>
        </g>
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-13)"  href="#path-12"></use>
            <use fill="#88D3AF" fill-rule="evenodd"  href="#path-12"></use>
        </g>
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-15)"  href="#path-14"></use>
            <use fill="#88D3AF" fill-rule="evenodd"  href="#path-14"></use>
        </g>
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-17)"  href="#path-16"></use>
            <use fill="#88D3AF" fill-rule="evenodd"  href="#path-16"></use>
        </g>
        <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-19)"  href="#path-18"></use>
            <use fill="#88D3AF" fill-rule="evenodd"  href="#path-18"></use>
        </g>
    </g>
    </svg>

*/
