import React from 'react';
// import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../components/Button';
import Card from '../components/Card';
import SplitSection from '../components/SplitSection';
import LinkingJobs from '../svg/linkingJobs';
import Pools from '../svg/Pools';
import Layout from '../components/layout/Layout';
import Pricing from '../svg/Pricing';
import Availabilities from '../svg/Availabilities';
import EmailNotification from '../svg/EmailNotification';
import SiteMetadata from '../components/site-metadata';

const Home = () => {
  // const endingAJobImage = getImage(data.file);

  return (
    <>
      <SiteMetadata pathname="" />
      <Layout>
        <SplitSection
          id="title"
          // className="container mx-auto px-4 lg:flex"
          primarySlot={
            <div>
              <div className="text-center lg:text-left">
                <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                  Jobs einfach und <br /> flexibel einteilen
                </h1>
                <p className="text-xl lg:text-2xl mt-6 font-light">
                  Mit uns wird das Verteilen von Einsätzen in Ihrem Arbeitnehmerpool zum
                  Kinderspiel.
                </p>
              </div>
            </div>
          }
          secondarySlot={<LinkingJobs />}
        />
        <section id="features" className="pt-24 lg:pt-16">
          <SplitSection
            reverseOrder
            //  className="lg:pl-32 xl:pl-48"
            primarySlot={
              <div>
                <h3 className="text-3xl font-semibold leading-tight">Verfügbarkeiten</h3>
                <p className="mt-8 text-xl font-light leading-relaxed">
                  Ihr Team meldet regelmäßig verfügbare Zeiten. So sehen Sie mit einem Blick, wer
                  für den Einsatz zur Verfügung steht. Mit nur einem Klick wählen Sie einen JiM für
                  den Einsatz aus.
                </p>
              </div>
            }
            secondarySlot={<Availabilities />}
          />
          <SplitSection
            id="services"
            // className="lg:pr-32 xl:pr-48"
            primarySlot={
              <div>
                <h3 className="text-3xl font-semibold leading-tight">
                  Automatische Benachrichtigungen
                </h3>
                <p className="mt-8 text-xl font-light leading-relaxed">
                  Wir informieren automatisch über verteilte Aufträge, wesentliche Änderungen oder
                  Absagen.
                </p>
              </div>
            }
            secondarySlot={<EmailNotification />}
          />
          <SplitSection
            reverseOrder
            // className="lg:pl-32 xl:pl-48 py-40"
            primarySlot={
              <div>
                <h3 className="text-3xl font-semibold leading-tight">Zeiterfassung</h3>
                <p className="mt-8 text-xl font-light leading-relaxed">
                  Die Einsätze werden online beendet und die Arbeitszeit hierbei erfasst. Das
                  schafft Überblick und vereinfacht die Abrechnung.
                </p>
              </div>
            }
            // secondarySlot={<GatsbyImage alt="Zeiterfassung" image={endingAJobImage} />}
            secondarySlot={<StaticImage alt="Zeiterfassung" src="../img/EndingAJob.png" />}
          />
          <SplitSection
            id="pools"
            // className="lg:pr-32 xl:pr-48"
            primarySlot={
              <div>
                <h3 className="text-3xl font-semibold leading-tight">Pools</h3>
                <p className="mt-8 text-xl font-light leading-relaxed">
                  Arbeitnehmer werden bei uns in Pools organisiert. So werden erstellte Jobs immer
                  für die richtige Zielgruppe sichtbar.
                </p>
              </div>
            }
            secondarySlot={<Pools />}
          />
        </section>
        <section id="pricing" className="pt-24 lg:pt-16">
          <SplitSection
            // className="container mx-auto p-0 lg:flex pb-0"
            primarySlot={
              <div>
                <div className="text-center lg:text-left">
                  <h2 className="text-4xl lg:text-5xl xl:text-4xl font-bold leading-none">
                    Flexible Preismodelle für Ihre Bedürfnisse
                  </h2>
                  <p className="text-xl lg:text-2xl mt-6 font-light">
                    Unsere Pläne lassen sich auf Wunsch monatlich ändern und passen sich Ihren
                    Erfordernissen an. So wachsen wir mit Ihren Anforderungen und bieten immer die
                    richtige Lösung an.
                  </p>
                </div>
              </div>
            }
            secondarySlot={<Pricing />}
          />
        </section>
        <section className="p-0">
          <div className="container mx-auto text-center">
            <div className="flex-1 px-3">
              <h2 className="text-3xl lg:text-5xl font-semibold mb-8">Unsere Pläne</h2>
              <h6>
                Preise für monatliche<sup>1</sup> Zahlungen. Falls du mehr benötigst, melde dich
                unter <a href="mailto:kontakt@morrowmed.de">kontakt@morrowmed.de</a>
              </h6>
              <br />
              <h6 className="font-bold">
                Einen Monat gibt es immer zum Testen - kostenlos und unverbindlich
              </h6>
            </div>
            <div className="flex flex-row sm:-mx-3 mt-12">
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">JiM Free</p>
                  <p className="mt-4">
                    Für kleine Tests.
                    <br />
                    <br />
                    bis zu 3 JiMs<sup>2</sup>
                    <br />
                    Keine Jobs
                    <br />
                    Maximal ein Pool
                    <br />
                    <br />
                    <p className="font-bold text-default text-xl">kostenlos</p>
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">JiM Small</p>
                  <p className="mt-4">
                    Für kleine Operationen.
                    <br />
                    <br />
                    bis zu 20 JiMs<sup>2</sup>
                    <br />
                    Unbegrenzt Jobs
                    <br />
                    Beliebig viele Pools
                    <br />
                    <br />
                    <p className="font-bold text-default text-xl">50€ im Monat</p>
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">JiM Medium</p>
                  <p className="mt-4">
                    Für größere Eingriffe.
                    <br />
                    <br />
                    bis 50 aktive JiMs<sup>2</sup>
                    <br />
                    Unbegrenzt Jobs
                    <br />
                    Beliebig viele Pools
                    <br />
                    <br />
                    <p className="font-bold text-default text-xl">100€ im Monat</p>
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">JiM Large</p>
                  <p className="mt-4">
                    Wenn's mal mehr werden.
                    <br />
                    <br />
                    bis 100 aktive JiMs<sup>2</sup>
                    <br />
                    Unbegrenzt Jobs
                    <br />
                    Beliebig viele Pools
                    <br />
                    <br />
                    <p className="font-bold text-default text-xl">180€ im Monat</p>
                  </p>
                </Card>
              </div>
            </div>
          </div>
          <div className="text-center">
            <p className="font-light text-xs">
              <sup>1</sup>Als Berechnungsgrundlage gilt der erste Tag nach Ablauf der einmonatigen
              Testphase.
              <br />
              <sup>2</sup>Die angegebene Anzahl JiMs bezieht sich auf die maximale Anzahl an JiMs,
              die Sie Ihren Pools hinzufügen können. JiMs, die Teil mehrerer Pools sind, werden nur
              einfach gezählt.
            </p>
          </div>
        </section>
        <section id="contact" className="container mx-auto pb-24 pt-44 lg:py-24">
          <div className="p-8 bg-gray-200 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">Haben Sie Interesse?</h3>
            <p className="mt-8 text-xl font-light">
              Gerne stellen wir Ihnen unsere Plattform zur Verfügung und beantworten alle Ihre
              Fragen! <br />
              In einer einmonatigen unverbindlichen Testphase können Sie sich Ihr eigenes Bild
              machen.
            </p>
            <p className="mt-8">
              <a href="mailto:kontakt@morrowmed.de">
                <Button size="xl">Schreiben Sie uns</Button>
              </a>
            </p>
            <a href="mailto:kontakt@morrowmed.de">
              <p className="mt-4 text-gray-600">kontakt@morrowmed.de</p>
            </a>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Home;

/*
export const pageQuery = graphql`
  query {
    endingAJob: file(relativePath: { eq: "EndingAJob.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: FIXED)
      }
    }
    jobEdit: file(relativePath: { eq: "JobEdit.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: FIXED)
      }
    }
  }
`;
*/
