import React from 'react';

/* RAN SVG CODE THROUGH https://transform.tools/ (recommended by material-UI) */

const Pricing = () => (
  <svg width="100%" viewBox="0 0 880 536">
    <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
      <path
        d="M58.235 391.7l-5.333-21.034a273.135 273.135 0 00-28.978-10.396l-.674 9.775-2.73-10.77C8.308 355.789 0 354.253 0 354.253s11.222 42.674 34.76 75.299l27.425 4.817-21.306 3.072a110.503 110.503 0 009.53 10.1c34.24 31.782 72.377 46.364 85.182 32.57 12.804-13.795-4.573-50.742-38.813-82.524-10.615-9.853-23.95-17.76-37.297-23.983L58.235 391.7z"
        fill="#E6E6E6"
      />
      <path
        d="M108.955 368.858l6.308-20.763a273.133 273.133 0 00-19.433-23.879l-5.63 8.02 3.23-10.63c-8.651-9.298-14.97-14.907-14.97-14.907s-12.45 42.331-9.165 82.426l20.987 18.3-19.827-8.383a110.504 110.504 0 002.938 13.572c12.883 44.906 37.992 77.102 56.084 71.911 18.091-5.19 22.314-45.8 9.431-90.706-3.994-13.921-11.322-27.582-19.531-39.809l-10.422 14.848z"
        fill="#E6E6E6"
      />
      <path
        d="M140.378 255.613s-6.915 83.148-8.4 100.966a126.71 126.71 0 01-7.424 32.665s-2.97 5.939-2.97 11.878v75.723s-5.939 0-4.454 5.939c1.485 5.939 2.97 16.332 2.97 16.332h22.271s-1.485-4.454 2.97-5.939c4.454-1.484 0-13.363 0-13.363l23.756-89.086 35.634-74.238s20.787 66.814 25.242 74.238c0 0 16.332 83.147 19.302 90.571 2.97 7.424 4.454 7.424 2.969 10.394-1.485 2.97-1.485 5.939 0 7.423 1.485 1.485 29.695 0 29.695 0l-7.423-105.418-13.363-126.206-68.3-20.786-52.475 8.907z"
        fill="#2F2E41"
      />
      <path
        d="M136.432 487.238s-7.424-26.726-19.302-5.939-11.878 28.21-11.878 28.21-19.302 28.211 7.424 25.242c26.726-2.97 20.787-16.333 20.787-16.333s10.393-5.939 10.393-10.393c0-4.454-7.424-20.787-7.424-20.787zM256.698 484.269s7.424-26.726 19.302-5.94c11.879 20.787 11.879 28.211 11.879 28.211s19.302 28.21-7.424 25.241c-26.726-2.97-20.787-16.332-20.787-16.332s-10.393-5.94-10.393-10.394 7.423-20.786 7.423-20.786z"
        fill="#2F2E41"
      />
      <path
        d="M186.914 55.17s2.97 19.303-5.939 23.757c-8.908 4.454 19.302 20.787 26.726 20.787 7.424 0 20.787-14.848 20.787-14.848s-7.424-22.272-5.94-26.726l-35.634-2.97z"
        fill="#A0616A"
      />
      <circle fill="#A0616A" cx={203.24669} cy={38.8380805} r={31.18014} />
      <path
        d="M264.122 102.683l-37.118-22.488s-21.119 23.973-43.967-2.753l-39.181 10.393 5.939 100.965s-8.909 40.088-5.94 47.512c2.97 7.424-7.423 10.394-4.453 11.878 2.97 1.485 0 11.879 0 11.879s57.906 29.695 121.75 7.423l-5.938-19.302a15.58 15.58 0 00-2.97-14.847s5.94-8.909-1.485-16.333c0 0 2.97-13.363-4.454-19.302l-1.485-20.786 4.454-10.394 14.848-63.845z"
        fill="#CFCCE0"
      />
      <path
        d="M187.29 24.36s-3.718 8.045 11.152 4.827c0 0 5.576 3.218 5.576-1.609 0 0 7.434 8.045 9.293 3.218 1.858-4.827 5.576 1.609 5.576 1.609l3.717-8.045 3.717 4.827h13.01s3.718-32.179-40.89-28.96c-44.607 3.217-22.663 54.201-22.663 54.201s.36-9.152 4.077-4.325c3.717 4.827 7.435-25.743 7.435-25.743z"
        fill="#2F2E41"
      />
      <path
        d="M277.485 162.074l1.485 31.18s5.939 57.906-5.94 83.147v13.363s-2.969 34.15-17.816 32.665c-14.848-1.485 4.454-48.997 4.454-48.997l-2.97-65.33-2.97-37.12 23.757-8.908z"
        fill="#A0616A"
      />
      <path
        d="M247.79 104.168l14.811-2.406a72.313 72.313 0 0120.823 38.04c4.454 23.757 5.94 28.211 5.94 28.211l-35.635 8.909-19.302-37.12 13.363-35.634z"
        fill="#CFCCE0"
      />
      <path fill="#FFF" d="M184.0166 87.2232105H418.32716000000005V203.6177405H184.0166z" />
      <path
        d="M209.602 94.223c-6.02-3.62-13.278-3.344-16.037-3.067 1.05 2.565 4.203 9.109 10.223 12.73 6.034 3.63 13.283 3.345 16.038 3.07-1.05-2.564-4.204-9.11-10.224-12.733zM237.889 166.056h-37.624a8.465 8.465 0 100 16.93h37.624a8.465 8.465 0 100-16.93z"
        fill="#991A5F"
      />
      <path
        d="M408.261 76.7H197.318a18.686 18.686 0 00-18.686 18.686V198.16a18.686 18.686 0 0018.686 18.686H408.26c10.32 0 18.686-8.366 18.686-18.686V95.386A18.686 18.686 0 00408.261 76.7zM192.1 89.446c.416-.075 10.294-1.756 18.473 3.166 8.179 4.92 11.32 14.435 11.45 14.838l.331 1.026-1.061.188a28.175 28.175 0 01-4.578.336 27.256 27.256 0 01-13.896-3.502c-8.179-4.92-11.32-14.435-11.45-14.837l-.33-1.026 1.06-.189zm45.79 95.421h-37.624c-5.714 0-10.346-4.632-10.346-10.346s4.632-10.347 10.346-10.347h37.624c5.714 0 10.346 4.633 10.346 10.347 0 5.714-4.632 10.346-10.346 10.346zm171.187-1.88c-.018 6.225-5.061 11.268-11.287 11.286H369.57c-6.226-.018-11.269-5.06-11.287-11.287v-16.93c.018-6.227 5.061-11.27 11.287-11.287h28.218c6.226.018 11.269 5.06 11.287 11.287v16.93z"
        fill="#991A5F"
      />
      <path
        d="M378.507 179.694h-12.228a3.292 3.292 0 100 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.703h-12.228a1.41 1.41 0 110-2.822h12.228a1.41 1.41 0 010 2.822zM401.081 179.694h-12.228a3.292 3.292 0 100 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.703h-12.228a1.41 1.41 0 110-2.822h12.228a1.41 1.41 0 010 2.822zM378.507 171.229h-12.228a3.292 3.292 0 000 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.703h-12.228a1.41 1.41 0 010-2.822h12.228a1.41 1.41 0 110 2.822zM401.081 171.229h-12.228a3.292 3.292 0 000 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.703h-12.228a1.41 1.41 0 010-2.822h12.228a1.41 1.41 0 110 2.822zM378.507 162.764h-12.228a3.292 3.292 0 100 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.702h-12.228a1.41 1.41 0 110-2.821h12.228a1.41 1.41 0 010 2.821zM401.081 162.764h-12.228a3.292 3.292 0 100 6.584h12.228a3.292 3.292 0 000-6.584zm0 4.702h-12.228a1.41 1.41 0 110-2.821h12.228a1.41 1.41 0 010 2.821z"
        fill="#991A5F"
      />
      <path
        d="M121.584 144.257s-13.362 56.42 2.97 56.42 54.936-63.844 54.936-63.844 40.09-20.787 23.757-28.21c-16.333-7.425-37.12 11.877-37.12 11.877l-23.985 33.3-1.256-16.967-19.302 7.424z"
        fill="#A0616A"
      />
      <path
        d="M152.765 96.744l-8.91-8.909s-17.816 10.394-19.301 20.787c-1.485 10.394-8.909 43.058-8.909 43.058l27.469 2.228 8.908-13.363.743-43.8z"
        fill="#CFCCE0"
      />
      <path
        d="M574.783 75.623c-1.119-3.303-15.146-14.53-6.55-16.417l7.048 9.399 32.407-32.406 3.26 3.259-36.165 36.165zM574.783 185.817c-1.119-3.303-15.146-14.53-6.55-16.417l7.048 9.399 32.407-32.406 3.26 3.259-36.165 36.165zM574.783 297.585c-1.119-3.303-15.146-14.53-6.55-16.417l7.048 9.399 32.407-32.406 3.26 3.259-36.165 36.165z"
        fill="#991A5F"
      />
      <circle fill="#3F3D56" cx={672.94142} cy={62.2232605} r={10} />
      <path fill="#3F3D56" d="M710.4412 61.2231905H871.4412V63.2231905H710.4412z" />
      <circle fill="#3F3D56" cx={672.94142} cy={172.417241} r={10} />
      <path fill="#3F3D56" d="M710.4412 171.417031H871.4412V173.417031H710.4412z" />
      <circle fill="#3F3D56" cx={672.94142} cy={284.185421} r={10} />
      <path fill="#3F3D56" d="M710.4412 283.185101H871.4412V285.185101H710.4412z" />
      <path
        fill="#3F3D56"
        d="M600.03998 312.485001L550.03998 312.485001 550.03998 261.485001 584.03998 261.485001 584.03998 263.485001 552.03998 263.485001 552.03998 310.485001 598.03998 310.485001 598.03998 287.485001 600.03998 287.485001z"
      />
      <path
        fill="#3F3D56"
        d="M600.03998 200.485001L550.03998 200.485001 550.03998 149.485001 584.03998 149.485001 584.03998 151.485001 552.03998 151.485001 552.03998 198.485001 598.03998 198.485001 598.03998 175.485001 600.03998 175.485001z"
      />
      <path
        fill="#3F3D56"
        d="M600.03998 86.4850005L550.03998 86.4850005 550.03998 35.4850005 584.03998 35.4850005 584.03998 37.4850005 552.03998 37.4850005 552.03998 84.4850005 598.03998 84.4850005 598.03998 61.4850005 600.03998 61.4850005z"
      />
    </g>
  </svg>
);

export default Pricing;

/*
<svg width="872px" height="536px" viewBox="0 0 1400 536">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="undraw_online_payments_luau" transform="translate(0.000020, 0.999999)" fill-rule="nonzero">
            <path d="M58.23453,391.700381 L52.90153,370.666091 C43.4453723,366.658576 33.7707081,363.187601 23.92363,360.269701 L23.25026,370.045191 L20.51983,359.275591 C8.30783,355.788421 0,354.252381 0,354.252381 C0,354.252381 11.22195,396.926041 34.7592,429.550591 L62.1852,434.368141 L40.87866,437.440141 C43.8406702,441.003337 47.0238643,444.376704 50.40939,447.540261 C84.64939,479.322331 122.78639,493.904131 135.59068,480.109561 C148.39497,466.314991 131.01786,429.367861 96.77782,397.585781 C86.16308,387.733021 72.8287,379.826271 59.48107,373.603491 L58.23453,391.700381 Z" id="Path" fill="#E6E6E6"></path>
            <path d="M108.95533,368.857621 L115.26271,348.094701 C109.239299,339.776182 102.751496,331.804022 95.83021,324.216201 L90.20081,332.236391 L93.43024,321.605761 C84.77874,312.308181 78.46083,306.698901 78.46083,306.698901 C78.46083,306.698901 66.00926,349.030121 69.29452,389.124631 L90.28225,407.425241 L70.45497,399.041641 C71.1487789,403.622959 72.1300493,408.156105 73.39297,412.614231 C86.27578,457.519841 111.38538,489.715551 129.47687,484.525351 C147.56836,479.335151 151.79079,438.724471 138.90798,393.818861 C134.91418,379.897681 127.58637,366.236591 119.37675,354.010201 L108.95533,368.857621 Z" id="Path" fill="#E6E6E6"></path>
            <path d="M140.37783,255.612901 C140.37783,255.612901 133.46255,338.761381 131.97778,356.578601 C130.959664,367.747466 128.463241,378.731732 124.55394,389.243511 C124.55394,389.243511 121.5844,395.182591 121.5844,401.121661 L121.5844,476.844861 C121.5844,476.844861 115.64533,476.844861 117.1301,482.783941 C118.61487,488.723021 120.09963,499.116391 120.09963,499.116391 L142.37116,499.116391 C142.37116,499.116391 140.8864,494.662091 145.3407,493.177321 C149.795,491.692551 145.3407,479.814401 145.3407,479.814401 L169.097,390.728281 L204.73145,316.489851 C204.73145,316.489851 225.51821,383.304441 229.97252,390.728281 C229.97252,390.728281 246.30497,473.875331 249.27452,481.299171 C252.24407,488.723011 253.72882,488.723011 252.24405,491.692551 C250.75928,494.662091 250.75929,497.631621 252.24405,499.116391 C253.72881,500.601161 281.93943,499.116391 281.93943,499.116391 L274.51558,393.697821 L261.15267,267.492441 L192.85331,246.705681 L140.37783,255.612901 Z" id="Path" fill="#2F2E41"></path>
            <path d="M136.4321,487.238201 C136.4321,487.238201 129.00826,460.512371 117.1301,481.299131 C105.25194,502.085891 105.25195,509.509731 105.25195,509.509731 C105.25195,509.509731 85.94995,537.720341 112.67579,534.750801 C139.40163,531.781261 133.46255,518.418351 133.46255,518.418351 C133.46255,518.418351 143.85593,512.479271 143.85593,508.024971 C143.85593,503.570671 136.4321,487.238201 136.4321,487.238201 Z" id="Path" fill="#2F2E41"></path>
            <path d="M256.69836,484.268671 C256.69836,484.268671 264.1222,457.542831 276.00036,478.329591 C287.87852,499.116351 287.87851,506.540201 287.87851,506.540201 C287.87851,506.540201 307.18051,534.750801 280.45467,531.781261 C253.72883,528.811721 259.66791,515.448811 259.66791,515.448811 C259.66791,515.448811 249.27453,509.509731 249.27453,505.055431 C249.27453,500.601131 256.69836,484.268671 256.69836,484.268671 Z" id="Path" fill="#2F2E41"></path>
            <path d="M186.91423,55.1705305 C186.91423,55.1705305 189.88377,74.4725305 180.97516,78.9268205 C172.06655,83.3811105 200.27716,99.7135905 207.701,99.7135905 C215.12484,99.7135905 228.48776,84.8659005 228.48776,84.8659005 C228.48776,84.8659005 221.06391,62.5943705 222.54868,58.1400605 L186.91423,55.1705305 Z" id="Path" fill="#A0616A"></path>
            <circle id="Oval" fill="#A0616A" cx="203.24669" cy="38.8380805" r="31.18014"></circle>
            <path d="M264.1222,102.683121 L227.0042,80.1945205 C227.0042,80.1945205 205.8851,104.167891 183.03674,77.4420605 L143.85593,87.8354405 L149.79501,188.799701 C149.79501,188.799701 140.8864,228.888461 143.85593,236.312301 C146.82546,243.736141 136.43209,246.705681 139.40163,248.190451 C142.37117,249.675221 139.40163,260.068601 139.40163,260.068601 C139.40163,260.068601 197.30763,289.763971 261.15263,267.492441 L255.21355,248.190441 C256.856939,243.060645 255.733983,237.445872 252.24401,233.342751 C252.24401,233.342751 258.18309,224.434141 250.75925,217.010301 C250.75925,217.010301 253.72878,203.647381 246.30494,197.708301 L244.82017,176.921541 L249.27448,166.528161 L264.1222,102.683121 Z" id="Path" fill="#CFCCE0"></path>
            <path d="M187.28959,24.3602605 C187.28959,24.3602605 183.57229,32.4049505 198.44151,29.1870705 C198.44151,29.1870705 204.01751,32.4049505 204.01751,27.5781405 C204.01751,27.5781405 211.45212,35.6228305 213.31077,30.7960105 C215.16942,25.9691905 218.88677,32.4049505 218.88677,32.4049505 L222.60407,24.3602605 L226.32138,29.1870705 L239.33183,29.1870705 C239.33183,29.1870705 243.04913,-2.99167949 198.44147,0.226200511 C153.83381,3.44408051 175.77847,54.4281405 175.77847,54.4281405 C175.77847,54.4281405 176.13768,45.2764505 179.85498,50.1032705 C183.57228,54.9300905 187.28959,24.3602605 187.28959,24.3602605 Z" id="Path" fill="#2F2E41"></path>
            <path d="M277.48512,162.073901 L278.96989,193.254041 C278.96989,193.254041 284.90897,251.160041 273.03082,276.401041 L273.03082,289.763951 C273.03082,289.763951 270.06128,323.913631 255.21359,322.428861 C240.3659,320.944091 259.6679,273.431501 259.6679,273.431501 L256.69836,208.101681 L253.72883,170.982481 L277.48512,162.073901 Z" id="Path" fill="#A0616A"></path>
            <path d="M247.78975,104.167901 L262.60106,101.761521 C273.270441,112.012858 280.538404,125.290363 283.4242,139.802351 C287.8785,163.558651 289.36327,168.012951 289.36327,168.012951 L253.72883,176.921561 L234.42683,139.802341 L247.78975,104.167901 Z" id="Path" fill="#CFCCE0"></path>
            <rect id="Rectangle" fill="#FFFFFF" x="184.0166" y="87.2232105" width="234.31056" height="116.39453"></rect>
            <path d="M209.60189,94.2227205 C203.58222,90.6027205 196.32389,90.8792205 193.56459,91.1557205 C194.6154,93.7212105 197.7683,100.264911 203.78797,103.886721 C209.82234,107.515881 217.07103,107.231131 219.8262,106.956491 C218.77583,104.391901 215.62249,97.8454605 209.60189,94.2227205 Z" id="Path" fill="#991A5F"></path>
            <path d="M237.88889,166.055565 L200.26536,166.055565 C197.23881,166.052281 194.440754,167.665037 192.926528,170.28556 C191.412303,172.906082 191.412303,176.135649 192.926528,178.756171 C194.440754,181.376694 197.23881,182.98945 200.26536,182.986166 L237.88889,182.986166 C240.91544,182.98945 243.713496,181.376694 245.227722,178.756171 C246.741947,176.135649 246.741947,172.906082 245.227722,170.28556 C243.713496,167.665037 240.91544,166.052281 237.88889,166.055565 L237.88889,166.055565 Z" id="Path" fill="#991A5F"></path>
            <path d="M408.26129,76.6996705 L197.3177,76.6996705 C192.361896,76.6996705 187.609066,78.6683407 184.104783,82.1726234 C180.6005,85.6769061 178.63183,90.4297364 178.63183,95.3855405 L178.63183,198.161471 C178.63183,203.117271 180.600514,207.870096 184.104795,211.374376 C187.609075,214.878656 192.361899,216.847341 197.3177,216.847341 L408.26129,216.847341 C418.581211,216.847341 426.94716,208.481392 426.94716,198.161471 L426.94716,95.3855405 C426.94716,90.4297364 424.97849,85.6769061 421.474207,82.1726234 C417.969924,78.6683407 413.217094,76.6996705 408.26129,76.6996705 Z M192.09859,89.4463005 C192.51515,89.3709805 202.39271,87.6900405 210.57187,92.6116005 C218.75058,97.5322305 221.89292,107.047421 222.02244,107.449751 L222.35311,108.475751 L221.29173,108.664051 C219.777559,108.900421 218.246627,109.012858 216.71417,109.000241 C211.852623,109.093186 207.054719,107.883917 202.81799,105.497831 C194.63928,100.578111 191.4974,91.0629205 191.36789,90.6606005 L191.03721,89.6346005 L192.09859,89.4463005 Z M237.88889,184.867301 L200.26536,184.867301 C194.551162,184.867301 189.91889,180.235028 189.91889,174.520831 C189.91889,168.806633 194.551162,164.174361 200.26536,164.174361 L237.88889,164.174361 C243.603088,164.174361 248.23536,168.806633 248.23536,174.520831 C248.23536,180.235028 243.603088,184.867301 237.88889,184.867301 L237.88889,184.867301 Z M409.07598,182.986131 C409.057741,189.212231 404.015021,194.254952 397.78892,194.273191 L369.57127,194.273191 C363.345169,194.254952 358.302449,189.212231 358.28421,182.986131 L358.28421,166.055571 C358.302427,159.829461 363.34516,154.786727 369.57127,154.768511 L397.78892,154.768511 C404.01503,154.786727 409.057763,159.829461 409.07598,166.055571 L409.07598,182.986131 Z" id="Shape" fill="#991A5F"></path>
            <path d="M378.50683,179.694101 L366.27921,179.694101 C364.461055,179.694101 362.98715,181.168006 362.98715,182.986161 C362.98715,184.804315 364.461055,186.278221 366.27921,186.278221 L378.50683,186.278221 C380.324985,186.278221 381.79889,184.804315 381.79889,182.986161 C381.79889,181.168006 380.324985,179.694101 378.50683,179.694101 Z M378.50683,184.397046 L366.27921,184.397046 C365.774255,184.398385 365.30708,184.129766 365.054214,183.692685 C364.801347,183.255603 364.801347,182.716708 365.054214,182.279626 C365.30708,181.842545 365.774255,181.573926 366.27921,181.575265 L378.50683,181.575265 C379.284574,181.577341 379.913964,182.208409 379.913964,182.986156 C379.913964,183.763902 379.284574,184.39497 378.50683,184.397046 L378.50683,184.397046 Z" id="Shape" fill="#991A5F"></path>
            <path d="M401.08098,179.694101 L388.85333,179.694101 C387.035175,179.694101 385.56127,181.168006 385.56127,182.986161 C385.56127,184.804315 387.035175,186.278221 388.85333,186.278221 L401.08098,186.278221 C402.899135,186.278221 404.37304,184.804315 404.37304,182.986161 C404.37304,181.168006 402.899135,179.694101 401.08098,179.694101 Z M401.08098,184.397046 L388.85333,184.397046 C388.348375,184.398385 387.8812,184.129766 387.628334,183.692685 C387.375467,183.255603 387.375467,182.716708 387.628334,182.279626 C387.8812,181.842545 388.348375,181.573926 388.85333,181.575265 L401.08098,181.575265 C401.858724,181.577341 402.488114,182.208409 402.488114,182.986156 C402.488114,183.763902 401.858724,184.39497 401.08098,184.397046 L401.08098,184.397046 Z" id="Shape" fill="#991A5F"></path>
            <path d="M378.50683,171.228801 L366.27921,171.228801 C364.461056,171.228801 362.98715,172.702706 362.98715,174.520861 C362.98715,176.339015 364.461056,177.812921 366.27921,177.812921 L378.50683,177.812921 C380.324984,177.812921 381.79889,176.339015 381.79889,174.520861 C381.79889,172.702706 380.324984,171.228801 378.50683,171.228801 L378.50683,171.228801 Z M378.50683,175.931741 L366.27921,175.931741 C365.775151,175.931741 365.309381,175.662828 365.057352,175.226301 C364.805322,174.789773 364.805322,174.251948 365.057352,173.81542 C365.309381,173.378893 365.775151,173.109981 366.27921,173.109981 L378.50683,173.109981 C379.010889,173.109981 379.476659,173.378893 379.728688,173.81542 C379.980718,174.251948 379.980718,174.789773 379.728688,175.226301 C379.476659,175.662828 379.010889,175.931741 378.50683,175.931741 L378.50683,175.931741 Z" id="Shape" fill="#991A5F"></path>
            <path d="M401.08098,171.228801 L388.85333,171.228801 C387.035176,171.228801 385.56127,172.702706 385.56127,174.520861 C385.56127,176.339015 387.035176,177.812921 388.85333,177.812921 L401.08098,177.812921 C402.899134,177.812921 404.37304,176.339015 404.37304,174.520861 C404.37304,172.702706 402.899134,171.228801 401.08098,171.228801 L401.08098,171.228801 Z M401.08098,175.931741 L388.85333,175.931741 C388.349271,175.931741 387.883501,175.662828 387.631472,175.226301 C387.379442,174.789773 387.379442,174.251948 387.631472,173.81542 C387.883501,173.378893 388.349271,173.109981 388.85333,173.109981 L401.08098,173.109981 C401.585039,173.109981 402.050809,173.378893 402.302838,173.81542 C402.554868,174.251948 402.554868,174.789773 402.302838,175.226301 C402.050809,175.662828 401.585039,175.931741 401.08098,175.931741 L401.08098,175.931741 Z" id="Shape" fill="#991A5F"></path>
            <path d="M378.50683,162.763511 L366.27921,162.763511 C364.461055,162.763511 362.98715,164.237416 362.98715,166.055571 C362.98715,167.873725 364.461055,169.347631 366.27921,169.347631 L378.50683,169.347631 C380.324985,169.347631 381.79889,167.873725 381.79889,166.055571 C381.79889,164.237416 380.324985,162.763511 378.50683,162.763511 Z M378.50683,167.466456 L366.27921,167.466456 C365.774255,167.467795 365.30708,167.199176 365.054214,166.762095 C364.801347,166.325013 364.801347,165.786118 365.054214,165.349036 C365.30708,164.911955 365.774255,164.643336 366.27921,164.644675 L378.50683,164.644675 C379.284574,164.646751 379.913964,165.277819 379.913964,166.055566 C379.913964,166.833312 379.284574,167.46438 378.50683,167.466456 L378.50683,167.466456 Z" id="Shape" fill="#991A5F"></path>
            <path d="M401.08098,162.763511 L388.85333,162.763511 C387.035175,162.763511 385.56127,164.237416 385.56127,166.055571 C385.56127,167.873725 387.035175,169.347631 388.85333,169.347631 L401.08098,169.347631 C402.899135,169.347631 404.37304,167.873725 404.37304,166.055571 C404.37304,164.237416 402.899135,162.763511 401.08098,162.763511 Z M401.08098,167.466456 L388.85333,167.466456 C388.348375,167.467795 387.8812,167.199176 387.628334,166.762095 C387.375467,166.325013 387.375467,165.786118 387.628334,165.349036 C387.8812,164.911955 388.348375,164.643336 388.85333,164.644675 L401.08098,164.644675 C401.858724,164.646751 402.488114,165.277819 402.488114,166.055566 C402.488114,166.833312 401.858724,167.46438 401.08098,167.466456 L401.08098,167.466456 Z" id="Shape" fill="#991A5F"></path>
            <path d="M121.58441,144.256651 C121.58441,144.256651 108.2215,200.677851 124.55395,200.677851 C140.8864,200.677851 179.49039,136.832801 179.49039,136.832801 C179.49039,136.832801 219.57914,116.046041 203.24669,108.622201 C186.91424,101.198361 166.12747,120.500351 166.12747,120.500351 L142.14247,153.800261 L140.88637,136.832801 L121.58441,144.256651 Z" id="Path" fill="#A0616A"></path>
            <path d="M152.76456,96.7440505 L143.85594,87.8354405 C143.85594,87.8354405 126.03872,98.2288205 124.55394,108.622201 C123.06916,119.015581 115.64533,151.680491 115.64533,151.680491 L143.11355,153.907641 L152.02216,140.544721 L152.76456,96.7440505 Z" id="Path" fill="#CFCCE0"></path>
            <path d="M574.78269,75.6228005 C573.66414,72.3200105 559.63734,61.0929905 568.2326,59.2059405 L575.28126,68.6045205 L607.68787,36.1985205 L610.94716,39.4578105 L574.78269,75.6228005 Z" id="Path" fill="#991A5F"></path>
            <path d="M574.78269,185.816771 C573.66414,182.513991 559.63734,171.286971 568.2326,169.399921 L575.28126,178.798501 L607.68787,146.392501 L610.94716,149.651791 L574.78269,185.816771 Z" id="Path" fill="#991A5F"></path>
            <path d="M574.78269,297.584951 C573.66414,294.282171 559.63734,283.055151 568.2326,281.168101 L575.28126,290.566681 L607.68787,258.160681 L610.94716,261.419961 L574.78269,297.584951 Z" id="Path" fill="#991A5F"></path>
            <circle id="Oval" fill="#3F3D56" cx="672.94142" cy="62.2232605" r="10"></circle>
            <rect id="Rectangle" fill="#3F3D56" x="710.4412" y="61.2231905" width="161" height="2"></rect>
            <circle id="Oval" fill="#3F3D56" cx="672.94142" cy="172.417241" r="10"></circle>
            <rect id="Rectangle" fill="#3F3D56" x="710.4412" y="171.417031" width="161" height="2"></rect>
            <circle id="Oval" fill="#3F3D56" cx="672.94142" cy="284.185421" r="10"></circle>
            <rect id="Rectangle" fill="#3F3D56" x="710.4412" y="283.185101" width="161" height="2"></rect>
            <polygon id="Path" fill="#3F3D56" points="600.03998 312.485001 550.03998 312.485001 550.03998 261.485001 584.03998 261.485001 584.03998 263.485001 552.03998 263.485001 552.03998 310.485001 598.03998 310.485001 598.03998 287.485001 600.03998 287.485001"></polygon>
            <polygon id="Path" fill="#3F3D56" points="600.03998 200.485001 550.03998 200.485001 550.03998 149.485001 584.03998 149.485001 584.03998 151.485001 552.03998 151.485001 552.03998 198.485001 598.03998 198.485001 598.03998 175.485001 600.03998 175.485001"></polygon>
            <polygon id="Path" fill="#3F3D56" points="600.03998 86.4850005 550.03998 86.4850005 550.03998 35.4850005 584.03998 35.4850005 584.03998 37.4850005 552.03998 37.4850005 552.03998 84.4850005 598.03998 84.4850005 598.03998 61.4850005 600.03998 61.4850005"></polygon>
        </g>
    </g>
    </svg>
*/
