import React from 'react';

/* RAN SVG CODE THROUGH https://transform.tools/ (recommended by material-UI) */

const Pools = () => (
  <svg width="80%" viewBox="0 0 1110 830">
    <defs>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0.0043144361%" id="a">
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="-2.2033937e-15%" id="b">
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="99.9956856%" x2="50%" y2="0%" id="c">
        <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
        <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
        <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M926.25 410.58c-46.177 12.377-58.007 17.065-86.67 41.08-28.663 24.015-17.641 34.683-64.35 62.71-46.709 28.027-55.088 20.62-93.09 31.76s-46.674-.348-79.24 35.402c-32.566 35.75-23.654 26.467-29.18 83.618-5.526 57.15-16.338 78.87 13.57 116.166 29.908 37.296 64.814 40.88 114.43 43.453 49.616 2.574 56.117-5.409 100.65-32.299 44.533-26.89 43.064-52.479 72.37-72.175 29.306-19.696 13.784-.049 72.106-24.925 58.321-24.876 78.574-19.543 113.764-54.355s35.211-41.662 34.57-92.385c-.64-50.723-17.697-75.485-37.02-101.55-19.323-26.065-35.252-28.18-65.32-36.5-30.068-8.32-20.413-12.377-66.59 0z"
        fillOpacity={0.767327155}
        fill="#FFE6CF"
      />
      <path
        d="M570.222 28.19C613.504-.703 647.72.505 647.72.505s60.699 4.343 100.6 27.685c39.901 23.342 11.204 40.436 59.09 65.734 47.886 25.297 69.355 7.018 118.91 28.301 49.555 21.283 61.544 12.22 85.422 59.455 23.878 47.234 4.649 74.397 0 109.524-4.648 35.127 2.935 39.005-23.622 68.994-26.557 29.99-43.89 37.261-79.859 47.862-35.968 10.601-45.54 3.077-72.721 21.52-27.18 18.443-6.096 17.805-28.13 46.91-22.034 29.105-29.485 31.062-69.845 48.03s-46.54 20.002-91.948 19.993c-45.409-.015-52.663-12.9-90.757-20.043-38.094-7.143-30.473-9.787-77.19-11.45-46.717-1.663-51.483 10.333-94.18 5.35-42.697-4.983-50.463 2.1-81.67-25.874s-26.764-36.662-31.609-75.666c-4.845-39.004-1.351-43.562 12.339-79.455 13.69-35.893.028-43.467 44.062-68.415 44.034-24.948 76.341 2.25 118.788-23.848s34.9-44.352 51.154-80.637c10.836-24.19 17.122-49.405 18.856-75.645 7.687-21.164 25.957-41.378 54.812-60.64z"
        fill="#D0EAE3"
      />
      <path
        d="M31.647 229.05c0-19.862-.864-55.132 50.576-89.92 51.44-34.788 85.567-16.905 122.698-16.905s38.587.478 113.157 42.25 41.412 27.753 100.502 54.195c59.09 26.442 99.28 46.95 114.7 90.84 15.42 43.89-5.188 109.801 0 203.51 5.188 93.709 64.191 104.706 21.58 186.28-42.611 81.574-96.961 94.115-171.37 100.476-74.409 6.36-93.677-45.019-151.09-72.911-57.413-27.892-88.923-47.155-114.7-56.215-25.777-9.06-44.746-23.757-76.236-65.83C9.974 562.747.154 562.876.154 513.6c0-49.276 24.41-55.711 31.493-96.77 7.082-41.059 0-17.196 0-85.814V229.05z"
        fillOpacity={0.102912956}
        fill="#991A5F"
      />
      <g transform="translate(53.83 156.2)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M116.21 229.02a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={115.02} cy={112.76} r={50.29} />
        <path
          d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
          fill="#BE7C5E"
        />
        <path d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#BE7C5E" cx={115.02} cy={123.68} r={50.29} />
        <path
          d="M84.55 102.94c.59-2.17 1.33-4.56 3.28-5.69 1.54-.9 3.45-.76 5.23-.67 13.9.76 27.68-2.41 41.2-5.72 7.73-1.89 16-4.19 21-10.42 5-6.23-1.52-25.81-9.13-28.12-5-1.53-10.8 1.32-15.54-.92-2.11-1-3.66-2.87-5.48-4.33-3.73-3-8.52-4.21-13.16-5.35-5.42-1.34-11.28-2.65-16.43-.5-4 1.66-6.92 5.17-10.71 7.25-7.21 4-16 2.14-24.26 2.12-2.79 0-6.12.56-7.23 3.12-.75 1.74-.15 3.72-.07 5.61.24 6-4.71 10.67-8.86 15-4.15 4.33-8.07 10.51-5.45 15.89 1 2.05 2.83 3.6 3.87 5.63 1.79 3.52.89 7.77 1.54 11.66a29.34 29.34 0 003.27 8.4c3.08 6 18.55 38.3 25.66 30.09 3.44-4 4-15.56 5.39-20.76a1781.03 1781.03 0 015.88-22.29z"
          fill="#333"
        />
        <ellipse fill="#BE7C5E" cx={79.24} cy={121.85} rx={4.68} ry={8.77} />
      </g>
      <g transform="translate(552.19 284.43)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={116.11} cy={115.15} r={113.44} />
        <path
          d="M116.21 229.04a112.911 112.911 0 0062.32-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
          fill="#991A5F"
        />
        <circle fill="#F55F44" cx={115.01} cy={112.76} r={54.76} />
        <path
          d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39a3.35 3.35 0 013.35 3.35v24.72a17.54 17.54 0 01-17.54 17.54 17.54 17.54 0 01-17.54-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35h-.01z"
          fill="#FDA57D"
        />
        <path d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#FDA57D" cx={115.01} cy={123.68} r={50.29} />
        <circle fill="#F55F44" cx={114.94} cy={57.06} r={20.24} />
        <circle fill="#F55F44" cx={114.94} cy={37.13} r={13.29} />
        <path
          d="M106.16 68.16c-9.864-4.425-14.51-15.82-10.55-25.88a20.243 20.243 0 1036.9 16.55c-4.88 9.65-16.485 13.758-26.35 9.33z"
          fill="#F55F44"
        />
        <path
          d="M79.2 77.94s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.94z"
          fill="#000"
          opacity={0.1}
        />
        <path d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z" fill="#F55F44" />
        <path d="M95.53 61.64a249.85 249.85 0 0139.18 0s-28.94 2.69-39.18 0z" fill="#333" />
        <path
          d="M117.98 23.03a13.32 13.32 0 00-3 .23h.77a13.28 13.28 0 012 26.33c7.334.066 13.334-5.826 13.4-13.16.066-7.334-5.826-13.334-13.16-13.4h-.01z"
          fill="#F55F44"
        />
        <ellipse fill="#FDA57D" cx={64.71} cy={123.63} rx={4.68} ry={8.77} />
        <ellipse fill="#FDA57D" cx={165.3} cy={123.63} rx={4.68} ry={8.77} />
        <path fill="#F55F44" d="M76.03 78.57L85.84 73.51 88.05 81.62 82.04 85.71z" />
      </g>
      <g transform="translate(286.08 272.51)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#b)" />
        <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M71.64 116.27s-12.86 63.36-19.88 59.85c0 0 67.7 58.48 127.48 0 0 0-10.53-44.65-25.73-59.85H71.64z"
          fill="#333"
        />
        <path
          d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={115.01} cy={112.76} r={50.29} />
        <path
          d="M97.34 158.37h35.09v28.07c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-28.07h-.04z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-24.73a3.35 3.35 0 013.31-3.34z"
          fill="#BE7C5E"
        />
        <path d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#BE7C5E" cx={115.01} cy={123.67} r={50.29} />
        <path
          d="M66.94 104.57h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
          fill="#333"
        />
        <ellipse fill="#BE7C5E" cx={65.76} cy={121.53} rx={4.68} ry={8.77} />
        <ellipse fill="#BE7C5E" cx={164} cy={121.53} rx={4.68} ry={8.77} />
        <path
          d="M66.94 105.87h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
          fill="#000"
          opacity={0.1}
        />
      </g>
      <g transform="translate(286.08 272.51)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#b)" />
        <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M71.64 116.27s-12.86 63.36-19.88 59.85c0 0 67.7 58.48 127.48 0 0 0-10.53-44.65-25.73-59.85H71.64z"
          fill="#333"
        />
        <path
          d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={115.01} cy={112.76} r={50.29} />
        <path
          d="M97.34 158.37h35.09v28.07c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-28.07h-.04z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.675-7.835 17.523-17.51 17.54-9.687 0-17.54-7.853-17.54-17.54v-24.73a3.35 3.35 0 013.31-3.34z"
          fill="#BE7C5E"
        />
        <path d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#BE7C5E" cx={115.01} cy={123.67} r={50.29} />
        <path
          d="M66.94 104.57h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
          fill="#333"
        />
        <ellipse fill="#BE7C5E" cx={65.76} cy={121.53} rx={4.68} ry={8.77} />
        <ellipse fill="#BE7C5E" cx={164} cy={121.53} rx={4.68} ry={8.77} />
        <path
          d="M66.94 105.87h95.9s-8.19-38.72-44.44-36.25c-36.25 2.47-51.46 36.25-51.46 36.25z"
          fill="#000"
          opacity={0.1}
        />
      </g>
      <g transform="translate(756.34 142)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#c)" />
        <circle fill="#F5F5F5" cx={116.11} cy={115.14} r={113.44} />
        <path
          d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.19-22.85-20.09-24.16-20.09-24.16H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.57z"
          fill="#991A5F"
        />
        <circle fill="#72351C" cx={115.02} cy={112.75} r={54.76} />
        <path
          d="M97.34 158.36h35.09v28.07A17.54 17.54 0 01114.89 204c-9.687 0-17.54-7.853-17.54-17.54v-28.1h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.66 157.06h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35h-.01z"
          fill="#FDA57D"
        />
        <path d="M97.38 171.61a50.5 50.5 0 0035.09.1v-4.31H97.38v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#FDA57D" cx={115.02} cy={123.67} r={50.29} />
        <path
          d="M79.2 77.93s21.19 43 81 18L146.28 74.1l-24.67-8.86L79.2 77.93z"
          fill="#000"
          opacity={0.1}
        />
        <path d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z" fill="#72351C" />
        <path
          d="M79.01 74.39a29.05 29.05 0 017.2-11.71c9.89-9.78 26.11-11.84 34.43-23a6.51 6.51 0 01-3 9.18c8-.05 17.21-.75 21.71-7.31a14.91 14.91 0 01-2.51 15.74c7.06.32 14.6 5.11 15.06 12.17.3 4.7-2.64 9.15-6.51 11.85-3.87 2.7-8.53 3.91-13.13 4.91-13.42 3-62.01 15.41-53.25-11.83zM165.31 124.11h-1.33l-25.94 23.11c-25-11.7-43.34 0-43.34 0l-27.2-22.14-2.74.34c.772 27.464 23.443 49.225 50.915 48.873 27.473-.352 49.578-22.688 49.645-50.163l-.01-.02zm-50.36 31.95c-9.78 0-17.71-2-17.71-4.43 0-2.43 7.93-4.43 17.71-4.43 9.78 0 17.71 2 17.71 4.43 0 2.43-7.93 4.43-17.72 4.43h.01z"
          fill="#72351C"
        />
        <ellipse fill="#FDA57D" cx={64.71} cy={123.62} rx={4.68} ry={8.77} />
        <ellipse fill="#FDA57D" cx={165.3} cy={123.62} rx={4.68} ry={8.77} />
      </g>
      <g transform="translate(53 400.84)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={116.11} cy={115.15} r={113.44} />
        <path
          d="M116.21 229.04a112.912 112.912 0 0062.35-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.72s-15 1.23-19.7 22.25a112.922 112.922 0 0065.19 20.56z"
          fill="#991A5F"
        />
        <circle fill="#333" cx={115.01} cy={112.76} r={54.76} />
        <path
          d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.54-17.54v-28.07h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.74c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-24.75c0-1.85 1.5-3.35 3.35-3.35v.01z"
          fill="#FDB797"
        />
        <path d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.34H97.41v4.24z" fill="#000" opacity={0.1} />
        <circle fill="#FDB797" cx={115.01} cy={123.68} r={50.29} />
        <path
          d="M79.2 77.93s21.19 43 81 18L146.28 74.1l-24.67-8.86L79.2 77.93z"
          fill="#000"
          opacity={0.1}
        />
        <path d="M79.2 77.3s21.19 43 81 18l-13.92-21.83-24.67-8.86L79.2 77.3z" fill="#333" />
        <path
          d="M79.01 74.38a29.05 29.05 0 017.2-11.71c9.89-9.78 26.11-11.84 34.43-23a6.51 6.51 0 01-3 9.18c8-.05 17.21-.75 21.71-7.31a14.91 14.91 0 01-2.51 15.74c7.06.32 14.6 5.11 15.06 12.17.3 4.7-2.64 9.15-6.51 11.85-3.87 2.7-8.54 3.93-13.14 4.95-13.42 2.98-62 15.39-53.24-11.87z"
          fill="#333"
        />
        <ellipse fill="#FDB797" cx={64.7} cy={123.63} rx={4.68} ry={8.77} />
        <ellipse fill="#FDB797" cx={165.29} cy={123.63} rx={4.68} ry={8.77} />
      </g>
      <g transform="translate(596.66 560.69)">
        <circle
          cx={115.89}
          cy={115.89}
          r={115.89}
          opacity={0.5}
          fill="url(#a)"
          fillRule="nonzero"
        />
        <circle fill="#F5F5F5" fillRule="nonzero" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M116.21 229.04a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.76s-15 1.23-19.7 22.25a112.921 112.921 0 0065.15 20.56z"
          fill="#991A5F"
          fillRule="nonzero"
        />
        <path
          d="M97.34 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-28.07z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54a17.54 17.54 0 01-17.55-17.54v-24.72c0-1.85 1.5-3.35 3.35-3.35z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          d="M97.41 171.62a50.5 50.5 0 0035.09.1v-4.31H97.41v4.21z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <circle fill="#FDB797" fillRule="nonzero" cx={115.01} cy={123.68} r={50.29} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={65.76} cy={121.53} rx={4.68} ry={8.77} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={164} cy={121.53} rx={4.68} ry={8.77} />
        <rect
          stroke="#333"
          strokeWidth={2}
          x={84.37}
          y={123.92}
          width={21.51}
          height={10.76}
          rx={4.35}
        />
        <rect
          stroke="#333"
          strokeWidth={2}
          transform="rotate(180 134.905 129.29)"
          x={124.15}
          y={123.91}
          width={21.51}
          height={10.76}
          rx={4.35}
        />
        <path d="M105.06 124.96s6.82-3.7 19.5 0" stroke="#333" strokeWidth={2} />
        <path
          d="M149.78 87.23l12.34 28.67s15.09-19.02-12.34-28.67z"
          fill="#858585"
          fillRule="nonzero"
        />
        <path
          stroke="#333"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M145.66 129.3L164.08 112.85"
        />
        <path
          d="M80.26 87.23L67.92 115.9S52.83 96.88 80.26 87.23z"
          fill="#858585"
          fillRule="nonzero"
        />
        <path
          stroke="#333"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M84.37 129.3L65.7 112.85"
        />
      </g>
      <g transform="translate(530.83 26.37)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M178.64 125.46c0 41.78-27.26 88.29-60.89 88.29-33.63 0-60.86-46.51-60.86-88.29 0-41.78 27.26-63 60.89-63 33.63 0 60.86 21.23 60.86 63z"
          fill="#FDD835"
        />
        <path
          d="M116.21 229.05a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
          fill="#991A5F"
        />
        <path
          d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
          fill="#FDB797"
        />
        <path d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#FDB797" cx={115.02} cy={123.68} r={50.29} />
        <ellipse fill="#FDB797" cx={65.77} cy={121.53} rx={4.68} ry={8.77} />
        <ellipse fill="#FDB797" cx={164.01} cy={121.53} rx={4.68} ry={8.77} />
        <path
          d="M70.46 96.4s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M70.46 95.77s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
          fill="#FDD835"
        />
      </g>
      <g transform="translate(828.83 433.37)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={115.89} cy={115.26} r={113.44} />
        <path
          d="M178.64 125.46c0 41.78-27.26 88.29-60.89 88.29-33.63 0-60.86-46.51-60.86-88.29 0-41.78 27.26-63 60.89-63 33.63 0 60.86 21.23 60.86 63z"
          fill="#72351C"
        />
        <path
          d="M116.21 229.05a112.911 112.911 0 0062.36-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
          fill="#991A5F"
        />
        <path
          d="M97.35 158.37h35.09v28.07c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-28.07h-.01z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.7 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72c0 9.687-7.853 17.54-17.54 17.54s-17.54-7.853-17.54-17.54v-24.72a3.35 3.35 0 013.34-3.35z"
          fill="#FDB797"
        />
        <path d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#FDB797" cx={115.02} cy={123.68} r={50.29} />
        <ellipse fill="#FDB797" cx={65.77} cy={121.53} rx={4.68} ry={8.77} />
        <ellipse fill="#FDB797" cx={164.01} cy={121.53} rx={4.68} ry={8.77} />
        <path
          d="M70.46 96.4s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M70.46 95.77s28.43 38.91 93.63 25.62c0 0 7.28-8.86-10.44-36.06-17.72-27.2-67.06-22.77-83.19 10.44z"
          fill="#72351C"
        />
      </g>
      <g transform="translate(286.14 531.66)" fillRule="nonzero">
        <circle cx={115.89} cy={115.89} r={115.89} opacity={0.5} fill="url(#a)" />
        <circle fill="#F5F5F5" cx={116.12} cy={115.15} r={113.44} />
        <path
          d="M178.53 126.33c0 37.74-27.11 56.31-62.32 56.31s-65.16-18.57-65.16-56.31S79.59 58 114.79 58s63.74 30.56 63.74 68.33z"
          fill="#333"
        />
        <path
          d="M116.21 229.04a112.912 112.912 0 0062.37-18.66c-4.18-22.84-20.09-24.15-20.09-24.15H70.77s-15 1.23-19.7 22.25a112.921 112.921 0 0065.14 20.56z"
          fill="#991A5F"
        />
        <path
          d="M97.35 158.34h35.09v28.07a17.54 17.54 0 01-17.58 17.57 17.54 17.54 0 01-17.54-17.54v-28.1h.03z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M100.69 157.07h28.39c1.85 0 3.35 1.5 3.35 3.35v24.72a17.54 17.54 0 01-17.57 17.54 17.54 17.54 0 01-17.54-17.54v-24.73a3.35 3.35 0 013.35-3.35l.02.01z"
          fill="#FDA57D"
        />
        <path d="M97.42 171.62a50.5 50.5 0 0035.09.1v-4.31H97.42v4.21z" fill="#000" opacity={0.1} />
        <circle fill="#FDA57D" cx={115.02} cy={123.67} r={50.29} />
        <path
          d="M57.06 126.96s31.32-3.8 55-34.16c0 0 31.32 34.48 53.14 30.68 0 0 12.65-13.29 0-26.57-12.65-13.28-22.46-30.05-39.22-27.84-16.76 2.21-37.33 3.16-46.18 11.07-8.85 7.91-22.74 46.82-22.74 46.82z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M57.06 126.33s31.32-3.8 55-34.16c0 0 31.32 34.48 53.14 30.68 0 0 12.65-13.29 0-26.57-12.65-13.28-22.46-30.05-39.22-27.84-16.76 2.21-37.29 3.12-46.14 11.12-8.85 8-22.78 46.77-22.78 46.77z"
          fill="#333"
        />
        <ellipse fill="#FDA57D" cx={64.71} cy={123.63} rx={4.68} ry={8.77} />
        <ellipse fill="#FDA57D" cx={165.3} cy={123.63} rx={4.68} ry={8.77} />
      </g>
    </g>
  </svg>
);

export default Pools;

/*
<svg viewBox="0 0 1200 826">
        <defs>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="0.0043144361%" id="linearGradient-1">
                <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
                <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
                <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="50%" y1="100%" x2="50%" y2="-2.2033937e-15%" id="linearGradient-2">
                <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
                <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
                <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="50%" y1="99.9956856%" x2="50%" y2="0%" id="linearGradient-3">
                <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
                <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
                <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M926.25,410.58 C880.073242,422.957275 868.24327,427.64514 839.58,451.66 C810.91673,475.67486 821.938728,486.342983 775.23,514.37 C728.521272,542.397017 720.141616,534.989375 682.14,546.13 C644.138384,557.270625 635.466434,545.781936 602.9,581.532323 C570.333566,617.28271 579.24601,607.999492 573.72,665.15 C568.19399,722.300508 557.381966,744.019957 587.29,781.316078 C617.198034,818.612199 652.103584,822.196355 701.72,824.769433 C751.336416,827.342511 757.837285,819.360108 802.37,792.47 C846.902715,765.579892 845.434263,739.991061 874.74,720.295085 C904.045737,700.599109 888.523646,720.246319 946.845555,695.37 C1005.16746,670.493681 1025.41973,675.826957 1060.61,641.015076 C1095.80027,606.203196 1095.82136,599.353183 1095.18057,548.63 C1094.53978,497.906817 1077.48311,473.145468 1058.16,447.08 C1038.83689,421.014532 1022.90788,418.899898 992.84,410.58 C962.772116,402.260102 972.426758,398.202725 926.25,410.58 Z" id="Path-4" fill-opacity="0.767327155" fill="#FFE6CF"></path>
            <path d="M570.222048,28.19 C613.50414,-0.703455637 647.72,0.504944637 647.72,0.504944637 C647.72,0.504944637 708.418638,4.84766158 748.32,28.19 C788.221362,51.5323384 759.523842,68.6261164 807.41,93.923761 C855.296158,119.221406 876.764998,100.942344 926.32,122.225122 C975.875002,143.5079 987.864336,134.445587 1011.74238,181.68 C1035.62042,228.914413 1016.39056,256.076531 1011.74238,291.203622 C1007.0942,326.330713 1014.67681,330.209029 988.12,360.19833 C961.563194,390.18763 944.229259,397.458992 908.261068,408.06 C872.292877,418.661008 862.720791,411.137146 835.54,429.58 C808.359209,448.022854 829.443995,447.384583 807.41,476.49 C785.376005,505.595417 777.925317,507.551988 737.565,524.52 C697.204683,541.488012 691.025122,544.522427 645.616807,544.51259 C600.208492,544.497573 592.954497,531.612712 554.86,524.47 C516.765503,517.327288 524.386881,514.68291 477.67,513.02 C430.953119,511.35709 426.186586,523.353276 383.49,518.37 C340.793414,513.386724 333.027371,520.469307 301.82,492.4959 C270.612629,464.522492 275.05645,455.834302 270.211114,416.83 C265.365778,377.825698 268.859686,373.267844 282.55,337.375 C296.240314,301.482156 282.577816,293.907669 326.611957,268.96 C370.646098,244.012331 402.953156,271.209352 445.4,245.111866 C487.846844,219.01438 480.299325,200.759834 496.553938,164.475 C507.390346,140.28511 513.6757,115.07011 515.41,88.83 C523.096638,67.6656371 541.367321,47.4523038 570.222048,28.19 Z" id="Path-3" fill="#D0EAE3"></path>
            <path d="M31.647035,229.05 C31.647035,209.187892 30.7825731,173.917892 82.2232362,139.13 C133.663899,104.342108 167.79,122.225122 204.921068,122.225122 C242.052137,122.225122 243.508332,122.703315 318.077989,164.475 C392.647646,206.246685 359.49,192.228134 418.58,218.67 C477.67,245.111866 517.86,265.62 533.28,309.51 C548.7,353.4 528.091984,419.311156 533.28,513.02 C538.468016,606.728844 597.471026,617.72598 554.86,699.3 C512.248974,780.87402 457.898869,793.415392 383.49,799.776121 C309.081131,806.13685 289.812631,754.757211 232.4,726.865001 C174.987369,698.972792 143.477114,679.71 117.7,670.65 C91.9228858,661.59 72.9537163,646.892688 41.4637155,604.82 C9.97371474,562.747312 0.153324323,562.875522 0.153324323,513.6 C0.153324323,464.324478 24.5648894,457.888564 31.647035,416.83 C38.7291807,375.771436 31.647035,399.633985 31.647035,331.01599 C31.647035,262.397995 31.647035,248.912108 31.647035,229.05 Z" id="Path-2" fill-opacity="0.102912956" fill="#991A5F"></path>
            <g id="Group-5" transform="translate(53.830000, 156.200000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M116.21,229.020153 C138.377739,229.056473 160.065803,222.566748 178.57,210.36 C174.39,187.52 158.48,186.21 158.48,186.21 L70.77,186.21 C70.77,186.21 55.77,187.44 51.07,208.46 C70.1400645,221.873899 92.8947959,229.055926 116.21,229.020153 L116.21,229.020153 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#333333" cx="115.02" cy="112.76" r="50.29"></circle>
                <path d="M97.35,158.37 L132.44,158.37 L132.44,186.44 C132.44,196.127075 124.587075,203.98 114.9,203.98 L114.9,203.98 C105.212925,203.98 97.36,196.127075 97.36,186.44 L97.36,158.37 L97.35,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.7,157.07 L129.09,157.07 C130.940154,157.07 132.44,158.569846 132.44,160.42 L132.44,185.14 C132.44,194.827075 124.587075,202.68 114.9,202.68 L114.9,202.68 C105.212925,202.68 97.36,194.827075 97.36,185.14 L97.36,160.42 C97.36,158.573744 98.8537525,157.075511 100.7,157.07 Z" id="Path" fill="#BE7C5E"></path>
                <path d="M97.42,171.62 C108.728691,175.846642 121.177402,175.882118 132.51,171.72 L132.51,167.41 L97.42,167.41 L97.42,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#BE7C5E" cx="115.02" cy="123.68" r="50.29"></circle>
                <path d="M84.55,102.94 C85.14,100.77 85.88,98.38 87.83,97.25 C89.37,96.35 91.28,96.49 93.06,96.58 C106.96,97.34 120.74,94.17 134.26,90.86 C141.99,88.97 150.26,86.67 155.26,80.44 C160.26,74.21 153.74,54.63 146.13,52.32 C141.13,50.79 135.33,53.64 130.59,51.4 C128.48,50.4 126.93,48.53 125.11,47.07 C121.38,44.07 116.59,42.86 111.95,41.72 C106.53,40.38 100.67,39.07 95.52,41.22 C91.52,42.88 88.6,46.39 84.81,48.47 C77.6,52.47 68.81,50.61 60.55,50.59 C57.76,50.59 54.43,51.15 53.32,53.71 C52.57,55.45 53.17,57.43 53.25,59.32 C53.49,65.32 48.54,69.99 44.39,74.32 C40.24,78.65 36.32,84.83 38.94,90.21 C39.94,92.26 41.77,93.81 42.81,95.84 C44.6,99.36 43.7,103.61 44.35,107.5 C45.0008073,110.451723 46.1038217,113.285154 47.62,115.9 C50.7,121.9 66.17,154.2 73.28,145.99 C76.72,141.99 77.28,130.43 78.67,125.23 C80.59,117.756667 82.55,110.326667 84.55,102.94 Z" id="Path" fill="#333333"></path>
                <ellipse id="Oval" fill="#BE7C5E" cx="79.24" cy="121.85" rx="4.68" ry="8.77"></ellipse>
            </g>
            <g id="Group-7" transform="translate(552.190000, 284.430000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" transform="translate(-0.000000, -0.000000)" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="116.11" cy="115.15" r="113.44"></circle>
                <path d="M116.21,229.04015 C138.364122,229.06862 160.037168,222.579226 178.53,210.38 C174.35,187.54 158.44,186.23 158.44,186.23 L70.76,186.23 C70.76,186.23 55.76,187.46 51.06,208.48 C70.1328544,221.895868 92.8913842,229.077992 116.21,229.04015 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#F55F44" cx="115.01" cy="112.76" r="54.76"></circle>
                <path d="M97.34,158.37 L132.43,158.37 L132.43,186.44 C132.43,196.127075 124.577075,203.98 114.89,203.98 L114.89,203.98 C105.202925,203.98 97.35,196.127075 97.35,186.44 L97.35,158.37 L97.34,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C129.968475,157.07 130.820561,157.422945 131.448808,158.051192 C132.077055,158.679439 132.43,159.531525 132.43,160.42 L132.43,185.14 C132.43,189.791897 130.582041,194.253265 127.292653,197.542653 C124.003265,200.832041 119.541897,202.68 114.89,202.68 L114.89,202.68 C110.238103,202.68 105.776735,200.832041 102.487347,197.542653 C99.197959,194.253265 97.35,189.791897 97.35,185.14 L97.35,160.42 C97.35,158.569846 98.8498461,157.07 100.7,157.07 L100.69,157.07 Z" id="Path" fill="#FDA57D"></path>
                <path d="M97.41,171.62 C108.718691,175.846642 121.167402,175.882118 132.5,171.72 L132.5,167.41 L97.41,167.41 L97.41,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDA57D" cx="115.01" cy="123.68" r="50.29"></circle>
                <circle id="Oval" fill="#F55F44" cx="114.94" cy="57.06" r="20.24"></circle>
                <circle id="Oval" fill="#F55F44" cx="114.94" cy="37.13" r="13.29"></circle>
                <path d="M106.16,68.16 C96.2959442,63.735355 91.6505423,52.3398099 95.61,42.28 C95.47,42.56 95.33,42.85 95.2,43.14 C92.2400545,49.7386955 93.0253142,57.4152537 97.2599793,63.2779894 C101.494645,69.1407251 108.535369,72.2989493 115.72998,71.5629895 C122.92459,70.8270296 129.180055,66.3086956 132.14,59.71 C132.27,59.42 132.39,59.13 132.51,58.83 C127.62939,68.4797489 116.025424,72.5884778 106.16,68.16 Z" id="Path" fill="#F55F44"></path>
                <path d="M79.2,77.94 C79.2,77.94 100.39,120.94 160.2,95.94 L146.28,74.11 L121.61,65.25 L79.2,77.94 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M79.2,77.3 C79.2,77.3 100.39,120.3 160.2,95.3 L146.28,73.47 L121.61,64.61 L79.2,77.3 Z" id="Path" fill="#F55F44"></path>
                <path d="M95.53,61.64 C108.569866,60.6144254 121.670134,60.6144254 134.71,61.64 C134.71,61.64 105.77,64.33 95.53,61.64 Z" id="Path" fill="#333333"></path>
                <path d="M117.98,23.03 C116.974523,22.993115 115.968104,23.0702738 114.98,23.26 L115.75,23.26 C122.52789,23.4768161 128.053635,28.7654948 128.567261,35.5273729 C129.080886,42.2892511 124.417376,48.3518952 117.75,49.59 C125.084341,49.6562738 131.083725,43.7643412 131.149999,36.43 C131.216274,29.0956587 125.324341,23.0962745 117.99,23.03 L117.98,23.03 Z" id="Path" fill="#F55F44"></path>
                <ellipse id="Oval" fill="#FDA57D" cx="64.71" cy="123.63" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDA57D" cx="165.3" cy="123.63" rx="4.68" ry="8.77"></ellipse>
                <polygon id="Path" fill="#F55F44" points="76.03 78.57 85.84 73.51 88.05 81.62 82.04 85.71"></polygon>
            </g>
            <g id="Group-4" transform="translate(286.080000, 272.510000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-2)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M71.64,116.27 C71.64,116.27 58.78,179.63 51.76,176.12 C51.76,176.12 119.46,234.6 179.24,176.12 C179.24,176.12 168.71,131.47 153.51,116.27 L71.64,116.27 Z" id="Path" fill="#333333"></path>
                <path d="M116.21,229.040153 C138.377739,229.076473 160.065803,222.586748 178.57,210.38 C174.39,187.54 158.48,186.23 158.48,186.23 L70.76,186.23 C70.76,186.23 55.76,187.46 51.06,208.48 C70.1328544,221.895868 92.8913842,229.077992 116.21,229.040153 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#333333" cx="115.01" cy="112.76" r="50.29"></circle>
                <path d="M97.34,158.37 L132.43,158.37 L132.43,186.44 C132.43,196.115371 124.595357,203.963451 114.92,203.98 L114.92,203.98 C105.232925,203.98 97.38,196.127075 97.38,186.44 L97.38,158.37 L97.34,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C130.930154,157.07 132.43,158.569846 132.43,160.42 L132.43,185.14 C132.43,194.815371 124.595357,202.663451 114.92,202.68 L114.92,202.68 C105.232925,202.68 97.38,194.827075 97.38,185.14 L97.38,160.41 C97.3853345,158.579252 98.8593746,157.091852 100.69,157.07 Z" id="Path" fill="#BE7C5E"></path>
                <path d="M97.41,171.62 C108.718691,175.846642 121.167402,175.882118 132.5,171.72 L132.5,167.41 L97.41,167.41 L97.41,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#BE7C5E" cx="115.01" cy="123.67" r="50.29"></circle>
                <path d="M66.94,104.57 L162.84,104.57 C162.84,104.57 154.65,65.85 118.4,68.32 C82.15,70.79 66.94,104.57 66.94,104.57 Z" id="Path" fill="#333333"></path>
                <ellipse id="Oval" fill="#BE7C5E" cx="65.76" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#BE7C5E" cx="164" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <path d="M66.94,105.87 L162.84,105.87 C162.84,105.87 154.65,67.15 118.4,69.62 C82.15,72.09 66.94,105.87 66.94,105.87 Z" id="Path" fill="#000000" opacity="0.1"></path>
            </g>
            <g id="Group-4" transform="translate(286.080000, 272.510000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-2)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M71.64,116.27 C71.64,116.27 58.78,179.63 51.76,176.12 C51.76,176.12 119.46,234.6 179.24,176.12 C179.24,176.12 168.71,131.47 153.51,116.27 L71.64,116.27 Z" id="Path" fill="#333333"></path>
                <path d="M116.21,229.040153 C138.377739,229.076473 160.065803,222.586748 178.57,210.38 C174.39,187.54 158.48,186.23 158.48,186.23 L70.76,186.23 C70.76,186.23 55.76,187.46 51.06,208.48 C70.1328544,221.895868 92.8913842,229.077992 116.21,229.040153 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#333333" cx="115.01" cy="112.76" r="50.29"></circle>
                <path d="M97.34,158.37 L132.43,158.37 L132.43,186.44 C132.43,196.115371 124.595357,203.963451 114.92,203.98 L114.92,203.98 C105.232925,203.98 97.38,196.127075 97.38,186.44 L97.38,158.37 L97.34,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C130.930154,157.07 132.43,158.569846 132.43,160.42 L132.43,185.14 C132.43,194.815371 124.595357,202.663451 114.92,202.68 L114.92,202.68 C105.232925,202.68 97.38,194.827075 97.38,185.14 L97.38,160.41 C97.3853345,158.579252 98.8593746,157.091852 100.69,157.07 Z" id="Path" fill="#BE7C5E"></path>
                <path d="M97.41,171.62 C108.718691,175.846642 121.167402,175.882118 132.5,171.72 L132.5,167.41 L97.41,167.41 L97.41,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#BE7C5E" cx="115.01" cy="123.67" r="50.29"></circle>
                <path d="M66.94,104.57 L162.84,104.57 C162.84,104.57 154.65,65.85 118.4,68.32 C82.15,70.79 66.94,104.57 66.94,104.57 Z" id="Path" fill="#333333"></path>
                <ellipse id="Oval" fill="#BE7C5E" cx="65.76" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#BE7C5E" cx="164" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <path d="M66.94,105.87 L162.84,105.87 C162.84,105.87 154.65,67.15 118.4,69.62 C82.15,72.09 66.94,105.87 66.94,105.87 Z" id="Path" fill="#000000" opacity="0.1"></path>
            </g>
            <g id="Group-3" transform="translate(756.340000, 142.000000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-3)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="116.11" cy="115.14" r="113.44"></circle>
                <path d="M116.21,229.040153 C138.377739,229.076473 160.065803,222.586748 178.57,210.38 C174.38,187.53 158.48,186.22 158.48,186.22 L70.77,186.22 C70.77,186.22 55.77,187.45 51.07,208.47 C70.1388858,221.887448 92.8937135,229.072999 116.21,229.040153 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#72351C" cx="115.02" cy="112.75" r="54.76"></circle>
                <path d="M97.34,158.36 L132.430026,158.36 L132.430026,186.43 C132.437965,191.087093 130.593523,195.556171 127.303272,198.852051 C124.01302,202.14793 119.5471,204 114.89,204 L114.89,204 C105.202925,204 97.35,196.147075 97.35,186.46 L97.35,158.36 L97.34,158.36 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.66,157.06 L129.05,157.06 C130.900154,157.06 132.4,158.559846 132.4,160.41 L132.4,185.13 C132.4,194.817075 124.547075,202.67 114.86,202.67 L114.86,202.67 C105.172925,202.67 97.32,194.817075 97.32,185.13 L97.32,160.41 C97.32,158.559846 98.8198461,157.06 100.67,157.06 L100.66,157.06 Z" id="Path" fill="#FDA57D"></path>
                <path d="M97.38,171.61 C108.688691,175.836642 121.137402,175.872118 132.47,171.71 L132.47,167.4 L97.38,167.4 L97.38,171.61 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDA57D" cx="115.02" cy="123.67" r="50.29"></circle>
                <path d="M79.2,77.93 C79.2,77.93 100.39,120.93 160.2,95.93 L146.28,74.1 L121.61,65.24 L79.2,77.93 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M79.2,77.3 C79.2,77.3 100.39,120.3 160.2,95.3 L146.28,73.47 L121.61,64.61 L79.2,77.3 Z" id="Path" fill="#72351C"></path>
                <path d="M79.01,74.39 C80.4386125,69.966862 82.9077363,65.9511065 86.21,62.68 C96.1,52.9 112.32,50.84 120.64,39.68 C121.55416,41.2772077 121.749592,43.1867149 121.177933,44.9359911 C120.606274,46.6852674 119.320939,48.110864 117.64,48.86 C125.64,48.81 134.85,48.11 139.35,41.55 C141.62322,46.8411302 140.646139,52.968323 136.84,57.29 C143.9,57.61 151.44,62.4 151.9,69.46 C152.2,74.16 149.26,78.61 145.39,81.31 C141.52,84.01 136.86,85.22 132.26,86.22 C118.84,89.22 70.25,101.63 79.01,74.39 Z" id="Path" fill="#72351C"></path>
                <path d="M165.31,124.11 L163.98,124.11 L138.04,147.22 C113.04,135.52 94.7,147.22 94.7,147.22 L67.5,125.08 L64.76,125.42 C65.5318779,152.884131 88.20251,174.645447 115.675225,174.293023 C143.147941,173.940598 165.252889,151.604894 165.32,124.13 L165.31,124.11 Z M114.95,156.06 C105.17,156.06 97.24,154.06 97.24,151.63 C97.24,149.2 105.17,147.2 114.95,147.2 C124.73,147.2 132.66,149.2 132.66,151.63 C132.66,154.06 124.73,156.06 114.94,156.06 L114.95,156.06 Z" id="Shape" fill="#72351C"></path>
                <ellipse id="Oval" fill="#FDA57D" cx="64.71" cy="123.62" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDA57D" cx="165.3" cy="123.62" rx="4.68" ry="8.77"></ellipse>
            </g>
            <g id="Group-6" transform="translate(53.000000, 400.840000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="116.11" cy="115.15" r="113.44"></circle>
                <path d="M116.21,229.040222 C138.374335,229.074509 160.058644,222.584867 178.56,210.38 C174.38,187.54 158.47,186.23 158.47,186.23 L70.72,186.23 C70.72,186.23 55.72,187.46 51.02,208.48 C70.1040133,221.903749 92.8777379,229.086258 116.21,229.040222 L116.21,229.040222 Z" id="Path" fill="#991A5F"></path>
                <circle id="Oval" fill="#333333" cx="115.01" cy="112.76" r="54.76"></circle>
                <path d="M97.34,158.37 L132.43,158.37 L132.43,186.44 C132.43,196.127075 124.577075,203.98 114.89,203.98 L114.89,203.98 C110.238103,203.98 105.776735,202.132041 102.487347,198.842653 C99.197959,195.553265 97.35,191.091897 97.35,186.44 L97.35,158.37 L97.34,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C130.930154,157.07 132.43,158.569846 132.43,160.42 L132.43,185.16 C132.43,194.847075 124.577075,202.700003 114.89,202.700003 L114.89,202.700003 C110.23637,202.702653 105.772433,200.855866 102.480882,197.56619 C99.1893309,194.276514 97.34,189.81363 97.34,185.16 L97.34,160.41 C97.34,158.559846 98.8398461,157.06 100.69,157.06 L100.69,157.07 Z" id="Path" fill="#FDB797"></path>
                <path d="M97.41,171.62 C108.718691,175.846642 121.167402,175.882118 132.5,171.72 L132.5,167.38 L97.41,167.38 L97.41,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDB797" cx="115.01" cy="123.68" r="50.29"></circle>
                <path d="M79.2,77.93 C79.2,77.93 100.39,120.93 160.2,95.93 L146.28,74.1 L121.61,65.24 L79.2,77.93 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M79.2,77.3 C79.2,77.3 100.39,120.3 160.2,95.3 L146.28,73.47 L121.61,64.61 L79.2,77.3 Z" id="Path" fill="#333333"></path>
                <path d="M79.01,74.38 C80.4386125,69.956862 82.9077363,65.9411065 86.21,62.67 C96.1,52.89 112.32,50.83 120.64,39.67 C121.55416,41.2672077 121.749592,43.1767149 121.177933,44.9259911 C120.606274,46.6752674 119.320939,48.100864 117.64,48.85 C125.64,48.8 134.85,48.1 139.35,41.54 C141.62322,46.8311302 140.646139,52.958323 136.84,57.28 C143.9,57.6 151.44,62.39 151.9,69.45 C152.2,74.15 149.26,78.6 145.39,81.3 C141.52,84 136.85,85.23 132.25,86.25 C118.83,89.23 70.25,101.64 79.01,74.38 Z" id="Path" fill="#333333"></path>
                <ellipse id="Oval" fill="#FDB797" cx="64.7" cy="123.63" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDB797" cx="165.29" cy="123.63" rx="4.68" ry="8.77"></ellipse>
            </g>
            <g id="Group-2" transform="translate(596.660000, 560.690000)">
                <g id="Group" opacity="0.5" transform="translate(0.000000, -0.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" fill-rule="nonzero" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M116.21,229.040153 C138.377739,229.076473 160.065803,222.586748 178.57,210.38 C174.39,187.54 158.48,186.23 158.48,186.23 L70.76,186.23 C70.76,186.23 55.76,187.46 51.06,208.48 C70.1328544,221.895868 92.8913842,229.077992 116.21,229.040153 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
                <path d="M97.34,158.37 L132.43,158.37 L132.43,186.44 C132.43,196.127075 124.577075,203.980003 114.89,203.980003 L114.89,203.980003 C110.23637,203.982653 105.772433,202.135866 102.480882,198.84619 C99.1893309,195.556514 97.34,191.09363 97.34,186.44 L97.34,158.37 Z" id="Path" fill="#000000" fill-rule="nonzero" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C130.930154,157.07 132.43,158.569846 132.43,160.42 L132.43,185.14 C132.43,194.827075 124.577075,202.680003 114.89,202.680003 L114.89,202.680003 C110.23637,202.682653 105.772433,200.835866 102.480882,197.54619 C99.1893309,194.256514 97.34,189.79363 97.34,185.14 L97.34,160.42 C97.34,158.569846 98.8398461,157.07 100.69,157.07 L100.69,157.07 Z" id="Path" fill="#FDB797" fill-rule="nonzero"></path>
                <path d="M97.41,171.62 C108.718691,175.846642 121.167402,175.882118 132.5,171.72 L132.5,167.41 L97.41,167.41 L97.41,171.62 Z" id="Path" fill="#000000" fill-rule="nonzero" opacity="0.1"></path>
                <circle id="Oval" fill="#FDB797" fill-rule="nonzero" cx="115.01" cy="123.68" r="50.29"></circle>
                <ellipse id="Oval" fill="#FDB797" fill-rule="nonzero" cx="65.76" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDB797" fill-rule="nonzero" cx="164" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <rect id="Rectangle" stroke="#333333" stroke-width="2" x="84.37" y="123.92" width="21.51" height="10.76" rx="4.35"></rect>
                <rect id="Rectangle" stroke="#333333" stroke-width="2" transform="translate(134.905000, 129.290000) rotate(180.000000) translate(-134.905000, -129.290000) " x="124.15" y="123.91" width="21.51" height="10.76" rx="4.35"></rect>
                <path d="M105.06,124.96 C105.06,124.96 111.88,121.26 124.56,124.96" id="Path" stroke="#333333" stroke-width="2"></path>
                <path d="M149.78,87.23 L162.12,115.9 C162.12,115.9 177.21,96.88 149.78,87.23 Z" id="Path" fill="#858585" fill-rule="nonzero"></path>
                <line x1="145.66" y1="129.3" x2="164.08" y2="112.85" id="Path" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
                <path d="M80.26,87.23 L67.92,115.9 C67.92,115.9 52.83,96.88 80.26,87.23 Z" id="Path" fill="#858585" fill-rule="nonzero"></path>
                <line x1="84.37" y1="129.3" x2="65.7" y2="112.85" id="Path" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            </g>
            <g id="Group-9" transform="translate(530.830000, 26.370000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M178.64,125.46 C178.64,167.24 151.38,213.75 117.75,213.75 C84.12,213.75 56.89,167.24 56.89,125.46 C56.89,83.68 84.15,62.46 117.78,62.46 C151.41,62.46 178.64,83.69 178.64,125.46 Z" id="Path" fill="#FDD835"></path>
                <path d="M116.21,229.050153 C138.377739,229.086473 160.065803,222.596748 178.57,210.39 C174.39,187.55 158.48,186.24 158.48,186.24 L70.77,186.24 C70.77,186.24 55.77,187.47 51.07,208.49 C70.1400645,221.903899 92.8947959,229.085926 116.21,229.050153 L116.21,229.050153 Z" id="Path" fill="#991A5F"></path>
                <path d="M97.35,158.37 L132.44,158.37 L132.44,186.44 C132.44,196.127075 124.587075,203.98 114.9,203.98 L114.9,203.98 C105.212925,203.98 97.36,196.127075 97.36,186.44 L97.36,158.37 L97.35,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.7,157.07 L129.09,157.07 C130.940154,157.07 132.44,158.569846 132.44,160.42 L132.44,185.14 C132.44,194.827075 124.587075,202.68 114.9,202.68 L114.9,202.68 C105.212925,202.68 97.36,194.827075 97.36,185.14 L97.36,160.42 C97.36,158.573744 98.8537525,157.075511 100.7,157.07 Z" id="Path" fill="#FDB797"></path>
                <path d="M97.42,171.62 C108.728691,175.846642 121.177402,175.882118 132.51,171.72 L132.51,167.41 L97.42,167.41 L97.42,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDB797" cx="115.02" cy="123.68" r="50.29"></circle>
                <ellipse id="Oval" fill="#FDB797" cx="65.77" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDB797" cx="164.01" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <path d="M70.46,96.4 C70.46,96.4 98.89,135.31 164.09,122.02 C164.09,122.02 171.37,113.16 153.65,85.96 C135.93,58.76 86.59,63.19 70.46,96.4 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M70.46,95.77 C70.46,95.77 98.89,134.68 164.09,121.39 C164.09,121.39 171.37,112.53 153.65,85.33 C135.93,58.13 86.59,62.56 70.46,95.77 Z" id="Path" fill="#FDD835"></path>
            </g>
            <g id="Group-9" transform="translate(828.830000, 433.370000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="115.89" cy="115.26" r="113.44"></circle>
                <path d="M178.64,125.46 C178.64,167.24 151.38,213.75 117.75,213.75 C84.12,213.75 56.89,167.24 56.89,125.46 C56.89,83.68 84.15,62.46 117.78,62.46 C151.41,62.46 178.64,83.69 178.64,125.46 Z" id="Path" fill="#72351C"></path>
                <path d="M116.21,229.050153 C138.377739,229.086473 160.065803,222.596748 178.57,210.39 C174.39,187.55 158.48,186.24 158.48,186.24 L70.77,186.24 C70.77,186.24 55.77,187.47 51.07,208.49 C70.1400645,221.903899 92.8947959,229.085926 116.21,229.050153 L116.21,229.050153 Z" id="Path" fill="#991A5F"></path>
                <path d="M97.35,158.37 L132.44,158.37 L132.44,186.44 C132.44,196.127075 124.587075,203.98 114.9,203.98 L114.9,203.98 C105.212925,203.98 97.36,196.127075 97.36,186.44 L97.36,158.37 L97.35,158.37 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.7,157.07 L129.09,157.07 C130.940154,157.07 132.44,158.569846 132.44,160.42 L132.44,185.14 C132.44,194.827075 124.587075,202.68 114.9,202.68 L114.9,202.68 C105.212925,202.68 97.36,194.827075 97.36,185.14 L97.36,160.42 C97.36,158.573744 98.8537525,157.075511 100.7,157.07 Z" id="Path" fill="#FDB797"></path>
                <path d="M97.42,171.62 C108.728691,175.846642 121.177402,175.882118 132.51,171.72 L132.51,167.41 L97.42,167.41 L97.42,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDB797" cx="115.02" cy="123.68" r="50.29"></circle>
                <ellipse id="Oval" fill="#FDB797" cx="65.77" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDB797" cx="164.01" cy="121.53" rx="4.68" ry="8.77"></ellipse>
                <path d="M70.46,96.4 C70.46,96.4 98.89,135.31 164.09,122.02 C164.09,122.02 171.37,113.16 153.65,85.96 C135.93,58.76 86.59,63.19 70.46,96.4 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M70.46,95.77 C70.46,95.77 98.89,134.68 164.09,121.39 C164.09,121.39 171.37,112.53 153.65,85.33 C135.93,58.13 86.59,62.56 70.46,95.77 Z" id="Path" fill="#72351C"></path>
            </g>
            <g id="Group-8" transform="translate(286.140000, 531.660000)" fill-rule="nonzero">
                <g id="Group" opacity="0.5" fill="url(#linearGradient-1)">
                    <circle id="Oval" cx="115.89" cy="115.89" r="115.89"></circle>
                </g>
                <circle id="Oval" fill="#F5F5F5" cx="116.12" cy="115.15" r="113.44"></circle>
                <path d="M178.53,126.33 C178.53,164.07 151.42,182.64 116.21,182.64 C81,182.64 51.05,164.07 51.05,126.33 C51.05,88.59 79.59,58 114.79,58 C149.99,58 178.53,88.56 178.53,126.33 Z" id="Path" fill="#333333"></path>
                <path d="M116.21,229.04017 C138.381143,229.078437 160.072963,222.588629 178.58,210.38 C174.4,187.54 158.49,186.23 158.49,186.23 L70.77,186.23 C70.77,186.23 55.77,187.46 51.07,208.48 C70.1400645,221.893899 92.8947959,229.075926 116.21,229.04017 L116.21,229.04017 Z" id="Path" fill="#991A5F"></path>
                <path d="M97.35,158.34 L132.440026,158.34 L132.440026,186.41 C132.447989,191.074032 130.598061,195.549191 127.29915,198.846227 C124.000238,202.143262 119.524027,203.990643 114.86,203.980046 L114.86,203.980046 C110.208103,203.980046 105.746735,202.132041 102.457347,198.842653 C99.167959,195.553265 97.32,191.091897 97.32,186.44 L97.32,158.34 L97.35,158.34 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M100.69,157.07 L129.08,157.07 C130.930154,157.07 132.43,158.569846 132.43,160.42 L132.43,185.14 C132.43,189.7971 130.57793,194.26302 127.282051,197.553272 C123.986171,200.843523 119.517093,202.687965 114.86,202.680026 L114.86,202.680026 C110.208103,202.680026 105.746735,200.832041 102.457347,197.542653 C99.167959,194.253265 97.32,189.791897 97.32,185.14 L97.32,160.41 C97.32,159.521525 97.6729454,158.669439 98.3011923,158.041192 C98.9294392,157.412945 99.7815248,157.06 100.67,157.06 L100.69,157.07 Z" id="Path" fill="#FDA57D"></path>
                <path d="M97.42,171.62 C108.728691,175.846642 121.177402,175.882118 132.51,171.72 L132.51,167.41 L97.42,167.41 L97.42,171.62 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <circle id="Oval" fill="#FDA57D" cx="115.02" cy="123.67" r="50.29"></circle>
                <path d="M57.06,126.96 C57.06,126.96 88.38,123.16 112.06,92.8 C112.06,92.8 143.38,127.28 165.2,123.48 C165.2,123.48 177.85,110.19 165.2,96.91 C152.55,83.63 142.74,66.86 125.98,69.07 C109.22,71.28 88.65,72.23 79.8,80.14 C70.95,88.05 57.06,126.96 57.06,126.96 Z" id="Path" fill="#000000" opacity="0.1"></path>
                <path d="M57.06,126.33 C57.06,126.33 88.38,122.53 112.06,92.17 C112.06,92.17 143.38,126.65 165.2,122.85 C165.2,122.85 177.85,109.56 165.2,96.28 C152.55,83 142.74,66.23 125.98,68.44 C109.22,70.65 88.69,71.56 79.84,79.56 C70.99,87.56 57.06,126.33 57.06,126.33 Z" id="Path" fill="#333333"></path>
                <ellipse id="Oval" fill="#FDA57D" cx="64.71" cy="123.63" rx="4.68" ry="8.77"></ellipse>
                <ellipse id="Oval" fill="#FDA57D" cx="165.3" cy="123.63" rx="4.68" ry="8.77"></ellipse>
            </g>
        </g>
    </svg>
*/
