import React from 'react';

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="py-16">
    <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 lg:px-8">
      <div className="flex items-center justify-center px-8">{primarySlot}</div>
      <div
        className={`flex items-center justify-center px-16 lg:px-8 ${reverseOrder &&
          `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
