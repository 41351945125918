import React from 'react';

/* RAN SVG CODE THROUGH https://transform.tools/ (recommended by material-UI) */

const LinkingJobs = () => (
  <svg width="100%" viewBox="0 50 820 700">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="nonzero">
      <path
        d="M246.81 165.114a554.594 554.594 0 01-4.83 71.29c-.11.83-.23 1.66-.34 2.49H18.36c-.66-.82-1.31-1.65-1.94-2.49-2.1-2.78-4.07-5.64-5.88-8.6-8.7-14.26-12.74-30.99-8.85-42.57l.07-.18a20.282 20.282 0 013.92-6.9c8.96-10.2 27.2-8.61 43.79-.51-14.93-16.16-26.86-36.59-27.86-54.88-.98-17.84 8.28-30.64 18.34-41.53.33-.36.66-.71.99-1.06.16-.18.33-.35.49-.53 7.85-8.3 17.01-16.49 30.34-15.58 14.62 1 30.89 13.15 42.33 27.35 11.44 14.19 19.08 30.34 26.82 46.1 7.75 15.75 16.13 31.9 28.5 45.33-16.77-20.97-30.33-44.86-35.42-68.14-5.09-23.28-.85-45.69 13.93-56.75a34.713 34.713 0 0115.17-6.22c.64-.11 1.29-.2 1.95-.28 13.73-1.64 30.16 3.02 44.18 14.3 15.44 12.42 26.2 30.9 31.6 48.47 5.4 17.57 6.06 34.51 5.98 50.89z"
        fill="#F2F2F2"
      />
      <path
        d="M309.14 187.894h-4.27c-1.36-.84-2.727-1.67-4.1-2.49-.85-.53-1.71-1.04-2.57-1.56a1167.134 1167.134 0 00-42.89-24.65 1110.827 1110.827 0 00-43.32-22.51 1.79 1.79 0 01-1.04-2.04.516.516 0 01.07-.15c.2-.38.65-.54 1.31-.22 1.78.88 3.57 1.76 5.35 2.66 14.55 7.26 29.04 14.886 43.47 22.88 14.42 7.99 28.76 16.336 43.02 25.04.3.18.6.37.9.55 1.36.83 2.71 1.66 4.07 2.49zM364.19 187.894h-2.64c-.6-.83-1.19-1.66-1.79-2.49-14.1-19.54-28.196-39.08-42.29-58.62-23.08-31.99-46.154-63.98-69.22-95.97a1.7 1.7 0 01-.3-.58c-.2-.71.32-1.13.99-1.06a2.006 2.006 0 011.4.88 3353838.901 3353838.901 0 01107.68 149.28c1.46 2.02 2.92 4.05 4.38 6.07.6.83 1.2 1.66 1.79 2.49zM422.92 172.234c-.11 4.45-.38 8.83-.74 13.17l-.21 2.49h-2.77c.08-.83.16-1.66.23-2.49.55-6.27.93-12.61.95-19.12a266.656 266.656 0 00-5.55-53.72 334.665 334.665 0 00-16.57-55.75 383.492 383.492 0 00-26.91-55.1 1.476 1.476 0 01-.25-.98c.11-.79 1.16-1.03 1.95-.28.156.147.29.315.4.5a410.58 410.58 0 013.8 6.59 383.227 383.227 0 0125.77 55.3 333.081 333.081 0 0115.47 55.84 263.074 263.074 0 014.43 53.55z"
        fill="#FFF"
      />
      <path
        d="M615.72 238.404h-607c-4.814.004-8.72 3.906-8.72 8.72v411.32c0 4.814 3.906 8.715 8.72 8.72h607a8.713 8.713 0 006.63-3.06c.07-.075.133-.156.19-.24a8.167 8.167 0 001.25-2.11 8.569 8.569 0 00.66-3.31v-411.32c0-4.818-3.913-8.72-8.73-8.72zm6.24 420.04a6.183 6.183 0 01-1.03 3.42 6.446 6.446 0 01-2.36 2.12 6.207 6.207 0 01-2.85.69h-607a6.232 6.232 0 01-6.23-6.23v-411.32a6.232 6.232 0 016.23-6.23h607c3.442 0 6.24 2.787 6.24 6.23v411.32z"
        fill="#3F3D56"
      />
      <path fill="#3F3D56" d="M1.24265 277.352895H623.1994100000001V279.845705H1.24265z" />
      <circle fill="#3F3D56" cx={22.43155} cy={261.099785} r={7.47844} />
      <circle fill="#3F3D56" cx={43.93206} cy={261.099785} r={7.47844} />
      <circle fill="#3F3D56" cx={65.43257} cy={261.099785} r={7.47844} />
      <path
        d="M277.977 394.274c-7.48-4.869-11.2-16.184-9.367-26.532 1.833-10.348 8.735-19.26 17.008-23.104a26.65 26.65 0 0125.228 1.793c7.587 4.64 18.317 7.18 21.92 16.462 2.769 7.133-.743 21.618-4.47 28.546-3.22 5.988-9.34 8.727-15.091 9.918a45.34 45.34 0 01-36.669-8.461l1.441 1.378z"
        fill="#2F2E41"
      />
      <path
        fill="#FFB8B8"
        d="M274.076 449.233975L268.096 459.543975 267.306 460.903975 266.156 462.903975 248.526 462.903975 249.716 460.903975 252.796 455.713975 259.976 443.593975 268.106 446.843975z"
      />
      <path
        d="M247.206 462.904l-3.22-2-.4-.25 20.21-36.83a13.73 13.73 0 018.25-6.62 13.575 13.575 0 016.78-.25c1.31.276 2.57.748 3.74 1.4.481.264.946.557 1.39.88a13.349 13.349 0 014.1 4.62 13.817 13.817 0 01-.42 13.95l-7.79 12.45-6.66 10.65-1.25 2h-24.73z"
        fill="#2F2E41"
      />
      <circle fill="#FFB8B8" cx={299.13014} cy={382.784155} r={24.56103} />
      <path
        d="M323.786 462.904h-55.21l.25-.71c-.14-.43-.27-.86-.39-1.29-.13-.45-.24-.91-.34-1.36a30.194 30.194 0 01.01-12.7c3.05-14.98 15.22-27.15 15.22-27.15.21-.16.42-.31.63-.46a27.785 27.785 0 015.85-3.28c9.04-3.75 18.52-2.24 24.89-.3a49.67 49.67 0 016.49 2.47c.74.35 1.14.57 1.14.57l.63 19.21.76 23 .07 2z"
        fill="#991A5F"
      />
      <path
        d="M349.326 460.904l-4.22-21.46-4.26-21.64a4.52 4.52 0 00-3.65-3.57l-8.92-1.54-8.3-1.43a4.46 4.46 0 00-4.31 1.65 4.41 4.41 0 00-.6 4.53c4.33 10.1 4.41 26.09 3.31 43.46-.05.66-.09 1.33-.14 2h31.49l-.4-2zM289.806 415.954a4.527 4.527 0 00-7.15-2.48l-3.83 3.48-15.68 14.28a4.345 4.345 0 00-1.12 1.59 995.68 995.68 0 00-9.23 22.89l-2.01 5.19c-.24.66-.5 1.33-.76 2h25.84c.21-.67.42-1.34.63-2 1.15-3.63 2.27-7.19 3.35-10.65 3.16-10.05 6.01-19.18 8.21-26.4a831 831 0 001.67-5.54c.235-.766.263-1.58.08-2.36z"
        fill="#2F2E41"
      />
      <path
        fill="#FFB8B8"
        d="M355.126 462.903975L356.796 462.903975 356.566 462.343975 355.956 460.903975 346.546 438.573975 345.106 439.443975 333.546 446.433975 339.476 460.903975 340.296 462.903975 349.726 462.903975z"
      />
      <path
        d="M345.586 420.864c-2.645-6.925-10.283-10.53-17.31-8.17-.432.143-.856.31-1.27.5a13.647 13.647 0 00-5.82 4.93 13.205 13.205 0 00-1.43 2.77 13.742 13.742 0 00.31 10.57l2.89 6.44 10.34 23 .9 2h20.93l1.44-.56 3.74-1.44.52-.2-15.24-39.84zM318.42 354.575a4.063 4.063 0 004.622-1.754 5.85 5.85 0 00.339-5.143 10.736 10.736 0 00-3.196-4.214 12.406 12.406 0 00-5.617-2.909 7.461 7.461 0 00-6.053 1.33 5.377 5.377 0 00-1.826 5.723c.636 1.845 2.359 3.08 4.079 4a27.094 27.094 0 0011.038 3.138l-3.386-.17zM323.198 382.05c-3.948.545-3.441-1.465-5.023-5.124-1.581-3.659-3.698-8.002-7.667-8.373-3.252-.303-5.932 2.297-8.567 4.227-4.696 3.442-11.026 5.126-16.47 3.062-5.443-2.065-9.226-8.553-7.16-13.996 1.299-3.039 3.8-5.402 6.906-6.53a24.028 24.028 0 019.622-1.336 49.647 49.647 0 0120.864 5.34c4.472 2.273 8.826 5.524 10.523 10.245 1.696 4.72 2.813 11.354-2.038 12.631l-.99-.145z"
        fill="#2F2E41"
      />
      <path
        d="M316.402 341.591c-1.952-3.397-.34-8.059 2.853-10.329 3.193-2.27 7.477-2.493 11.288-1.582a22.166 22.166 0 0115.9 15.411c1.617 5.941.656 12.323 2.025 18.326a24.049 24.049 0 0030.223 17.725c4.102-1.204 8.27-4.133 8.674-8.39a23.518 23.518 0 01-13.928 23.55c-5.285 2.204-11.18 2.348-16.905 2.201-3.73-.095-7.546-.32-10.988-1.763a19.667 19.667 0 01-10.333-11.204 31.129 31.129 0 01-1.376-15.435c.564-4.029 1.684-7.977 1.997-12.032.314-4.056-.28-8.386-2.78-11.594-2.5-3.209-7.277-4.847-10.831-2.87-1.429.794-2.581 2.092-4.133 2.605-1.553.513-3.752-.388-3.611-2.017l1.925-2.602z"
        fill="#2F2E41"
      />
      <path
        d="M484.006 462.904l.19-2 5.64-58.68a35.917 35.917 0 015.93-3.77c11.61-5.85 22.81-3.05 28.87-.63a31.591 31.591 0 014.64 2.27l3.3 60.81.11 2h-48.68z"
        fill="#991A5F"
      />
      <circle fill="#FFB8B8" cx={510.41886} cy={362.045395} r={24.56103} />
      <path
        d="M530.347 377.021a13.37 13.37 0 00-10.145-22.564c-3.397.114-6.597 1.504-9.838 2.524a32.535 32.535 0 01-15.285 1.376 20.41 20.41 0 01-13.017-7.686c-3.038-4.176-3.986-9.969-1.84-14.667 2.48-5.423 8.268-8.412 13.715-10.84a25.063 25.063 0 016.962-2.287 8.929 8.929 0 016.926 1.746c1.53 1.316 2.428 3.295 4.093 4.435 1.875 1.284 4.31 1.25 6.57 1.477a25.205 25.205 0 0112.209 45.54l-.35.946zM499.426 408.614l-3.66-10.16-.228-.634a2.104 2.104 0 00-3.147-1.036l-4.155 2.77s-26.65 0-28.79 15.99c-.03.25-.07.53-.1.83a.155.155 0 01-.01.04c-.27 1.05-5.3 20.49-9.62 44.49-.12.66-.24 1.33-.36 2h57.91c1.884-24.11.83-46.525-7.84-54.29zM568.506 460.904c-3.16-24.62-7.68-44.53-7.68-44.53-.04-.3-.08-.58-.11-.83-2.13-15.99-28.78-15.99-28.78-15.99l-4.153-2.769a2.104 2.104 0 00-3.147 1.039l-3.89 10.79c-8.67 7.765-9.724 30.18-7.84 54.289h55.85c-.08-.67-.17-1.34-.25-2z"
        fill="#2F2E41"
      />
      <path
        d="M99.426 404.844l-5.94 56.06-.21 2h-16.07c.19-.65.38-1.32.58-2 4.56-15.64 10.77-38.47 10.1-44.52-1.05-9.44 6.3-12.59 6.3-12.59l.46.09 4.78.96z"
        fill="#991A5F"
      />
      <circle fill="#A0616A" cx={125.34263} cy={362.979085} r={24.56103} />
      <path
        d="M157.136 451.004a40.266 40.266 0 00-3.03 7.16c-.29.88-.57 1.8-.83 2.74-.19.65-.36 1.32-.53 2h-64.78c.09-.66.17-1.33.26-2 3.31-26.15 5.99-52.69 6.42-57.02l.06-.62c38.82-20.46 67.15 6.3 67.15 6.3s.04.19.1.56c.62 3.74 3.78 25.58-4.82 40.88z"
        fill="#991A5F"
      />
      <path
        d="M154.106 458.164l-4.32-9.26 1.05-36.72 11.12-2.06.39-.07a29.445 29.445 0 0112.62 14.72c2.16 5.4 6.27 21.64 9.74 36.13.16.67.32 1.34.48 2h-28.87l-.93-2-1.28-2.74z"
        fill="#991A5F"
      />
      <path
        d="M193.616 297.904H51.596c-6.342.007-11.482 5.147-11.49 11.49v142.02c.008 6.342 5.148 11.482 11.49 11.49h142.02c6.344-.004 11.486-5.146 11.49-11.49v-142.02c-.004-6.344-5.146-11.486-11.49-11.49zm9.49 153.51a9.498 9.498 0 01-9.49 9.49H51.596a9.505 9.505 0 01-9.49-9.49v-142.02a9.505 9.505 0 019.49-9.49h142.02a9.498 9.498 0 019.49 9.49v142.02zm-109.62 9.49l-.21 2h59.47c.17-.68.34-1.35.53-2h-59.79zM384.226 297.904h-142.02c-6.342.007-11.482 5.147-11.49 11.49v142.02c.008 6.342 5.148 11.482 11.49 11.49h142.02c6.344-.004 11.486-5.146 11.49-11.49v-142.02c-.004-6.344-5.146-11.486-11.49-11.49zm9.49 153.51a9.498 9.498 0 01-9.49 9.49h-142.02a9.505 9.505 0 01-9.49-9.49v-142.02a9.505 9.505 0 019.49-9.49h142.02a9.498 9.498 0 019.49 9.49v142.02zm-125.28 9.49c.12.43.25.86.39 1.29l-.25.71h49.66c.05-.67.09-1.34.14-2h-49.94zM574.846 297.904h-142.02c-6.344.004-11.486 5.146-11.49 11.49v142.02c.004 6.344 5.146 11.486 11.49 11.49h142.02c6.343-.008 11.482-5.148 11.49-11.49v-142.02c-.008-6.343-5.147-11.483-11.49-11.49zm9.49 153.51a9.504 9.504 0 01-9.49 9.49h-142.02a9.498 9.498 0 01-9.49-9.49v-142.02a9.498 9.498 0 019.49-9.49h142.02a9.504 9.504 0 019.49 9.49v142.02zm-76.92 9.49l-.15 2h43.09c.08-.66.17-1.33.25-2h-43.19z"
        fill="#3F3D56"
      />
      <path
        d="M137.09 384.378a4.853 4.853 0 006.347.406c5.788-4.29 10.87-9.632 13.877-16.147 3.446-7.465 3.83-16.625-.324-23.72-3.512-5.997-9.715-9.868-15.896-13.046a28.27 28.27 0 00-7.674-2.957c-2.717-.6-5.56-.141-7.951 1.283a6.868 6.868 0 00-3.1 7.09 25.847 25.847 0 00-11.033-7.405 14.267 14.267 0 00-8.425-.611c-3.923 1.113-6.786 4.598-8.353 8.362a16.724 16.724 0 00-1.262 9.125c.47 3.12 2.24 5.894 4.87 7.635 3.244 2.006 7.381 1.862 11.106 1.042 3.724-.82 7.33-2.246 11.126-2.606 3.797-.36 8.024.586 10.335 3.62 1.627 2.136 2.045 4.928 2.41 7.589l1.9 13.84a16.88 16.88 0 001.51 5.782c.146.262.327.504.538.718z"
        fill="#2F2E41"
      />
      <g transform="translate(95 492)">
        <path
          d="M39.184 74.059c-14 0-26.935-7.058-33.934-18.515-7-11.457-7-25.572 0-37.03C12.25 7.059 25.185 0 39.184 0h356.632C417.456 0 435 16.579 435 37.03c0 20.45-17.543 37.029-39.184 37.029H39.184z"
          fill="#E6E6E6"
        />
        <path
          d="M39.184 4.83C20.366 4.83 5.11 19.246 5.11 37.03c0 17.783 15.255 32.199 34.073 32.199h356.632c18.818 0 34.073-14.416 34.073-32.2 0-17.783-15.255-32.2-34.073-32.2H39.184z"
          fill="#FFF"
        />
        <path fill="#E6E6E6" d="M146.230703 4.82910205H147.3664687V69.22807045H146.230703z" />
        <path fill="#E6E6E6" d="M287.634401 4.82910205H288.7701667V69.22807045H287.634401z" />
        <path fill="#991A5F" d="M147.366469 4.82910205H287.634401V69.22807045H147.366469z" />
        <path
          d="M218 18c-11.598 0-21 8.73-21 19.5S206.402 57 218 57s21-8.73 21-19.5S229.598 18 218 18zm-.5 5c3.59 0 6.5 2.686 6.5 6s-2.91 6-6.5 6-6.5-2.686-6.5-6c0-3.313 2.91-6 6.5-6zm0 29c-5.013-.021-9.69-2.303-12.5-6.096.1-3.808 8.333-5.904 12.5-5.904s12.4 2.096 12.5 5.904c-2.812 3.79-7.488 6.071-12.5 6.096z"
          fill="#FFF"
        />
        <path
          d="M76 18c-11.598 0-21 8.73-21 19.5S64.402 57 76 57s21-8.73 21-19.5S87.598 18 76 18zm.5 5c3.59 0 6.5 2.686 6.5 6s-2.91 6-6.5 6-6.5-2.686-6.5-6 2.91-6 6.5-6zm0 29c-5.013-.021-9.69-2.303-12.5-6.096C64.1 42.096 72.333 40 76.5 40s12.4 2.096 12.5 5.904c-2.812 3.79-7.488 6.071-12.5 6.096zM359 18c-11.598 0-21 8.73-21 19.5S347.402 57 359 57s21-8.73 21-19.5S370.598 18 359 18zm-.5 5c3.59 0 6.5 2.686 6.5 6s-2.91 6-6.5 6-6.5-2.686-6.5-6 2.91-6 6.5-6zm0 29c-5.013-.021-9.69-2.303-12.5-6.096.1-3.808 8.333-5.904 12.5-5.904s12.4 2.096 12.5 5.904c-2.812 3.79-7.488 6.071-12.5 6.096z"
          fill="#E6E6E6"
        />
        <rect
          fill="#991A5F"
          x={193.251229}
          y={101.047627}
          width={48.6260543}
          height={45.952373}
          rx={6}
        />
        <rect
          fill="#E6E6E6"
          x={334.527284}
          y={100.899923}
          width={48.6260543}
          height={45.952373}
          rx={6}
        />
        <path fill="#FFF" d="M341.473863 107.284841H376.8996802V140.76278589999998H341.473863z" />
        <path
          fill="#FFF"
          d="M215.283173 131.15874L209.139793 123.691479 212.712071 121.06563 215.620951 124.599859 225.448624 114.797233 228.735224 117.739006z"
        />
        <path
          fill="#E6E6E6"
          d="M356.790202 131.15874L350.646821 123.691479 354.219099 121.06563 357.127979 124.599859 366.954784 114.797233 370.241384 117.739006z"
        />
        <rect
          fill="#E6E6E6"
          x={51.8475316}
          y={100.899923}
          width={48.6260543}
          height={45.952373}
          rx={6}
        />
        <path fill="#FFF" d="M58.7941108 107.284841H94.219928V140.76278589999998H58.7941108z" />
        <path
          fill="#E6E6E6"
          d="M74.1104496 131.15874L67.9670686 123.691479 71.539347 121.06563 74.448227 124.599859 84.2750316 114.797233 87.5616319 117.739006z"
        />
      </g>
      <g transform="translate(546.942 268.662)">
        <ellipse fill="#E6E6E6" cx={134.439} cy={461.105281} rx={134.439} ry={18} />
        <path
          fill="#FFB8B8"
          d="M225.067 446.873281L211.887 446.873281 205.619 396.036281 225.071 396.036281z"
        />
        <path d="M202.467 443.106h25.418v16h-41.418c0-8.836 7.163-16 16-16z" fill="#2F2E41" />
        <path
          fill="#FFB8B8"
          d="M90.417 446.873281L77.237 446.873281 70.969 396.036281 90.421 396.036281z"
        />
        <path
          d="M67.823 443.106h25.418v16H51.819c0-8.836 7.163-16 16-16h.004zM134.439 69.782v-5.765c0-20.534 16.646-37.18 37.18-37.18s37.18 16.646 37.18 37.18v5.764c0 14.802-11.999 26.8-26.8 26.8h-20.756c-14.801 0-26.8-11.998-26.8-26.8l-.004.001z"
          fill="#2F2E41"
        />
        <circle fill="#FFB8B8" cx={171.62} cy={70.2542806} r={28.316} />
        <path
          d="M17.6 167.567a10.811 10.811 0 0116.463 1.934l24.273-4.59 6.388 14.07-34.37 6A10.869 10.869 0 0117.6 167.566z"
          fill="#FFB8B8"
        />
        <path
          d="M149.544 116.401l.317.481-39.8 26.221-67.164 21.447c-1.836.59-3 2.394-2.781 4.31l1.465 12.62a4.036 4.036 0 004.854 3.48l63.212-13.549a22.833 22.833 0 008.5-3.742l44.71-31.815c5.106-3.638 6.357-10.695 2.813-15.866-3.545-5.172-10.578-6.552-15.813-3.103l-.313-.484z"
          fill="#CCC"
        />
        <path
          d="M212.122 432.447a5.21 5.21 0 01-4.771-3.115l-60.421-149.3a1.729 1.729 0 00-3.238.182l-49.295 145.35a5.189 5.189 0 01-6.781 3.333l-16.53-6.2a5.175 5.175 0 01-3.34-4.27c-7.437-64.783 57.413-228.3 58.069-229.947l.182-.455 59.116 13.077.123.134c23.585 25.73 42.971 188.012 46.618 220.283a5.163 5.163 0 01-3.425 5.472l-14.591 5.16a5.14 5.14 0 01-1.716.296z"
          fill="#2F2E41"
        />
        <path
          d="M161.188 206.37c-14.323 0-30.291-2.856-35.206-14.642l-.113-.27.153-.252c3.88-6.366 9.007-17.224 6.251-19.263-5.429-4.014-8.064-10.618-7.83-19.628.508-19.559 13.835-36.925 33.163-43.212a147.146 147.146 0 0116.443-4.234 27.993 27.993 0 0123.21 5.732 28.276 28.276 0 0110.486 21.755c.2 20.9-3.015 50.015-19.5 70a5.128 5.128 0 01-3.036 1.765 140.9 140.9 0 01-24.021 2.25z"
          fill="#CCC"
        />
        <path
          d="M155.86 205.583c1.622-5.82 7.629-9.246 13.464-7.683.572.16 1.13.367 1.669.618l18.43-16.773 12.818 8.635-26.357 23.324a11 11 0 01-12.466 5.288 10.83 10.83 0 01-7.558-13.409z"
          fill="#FFB8B8"
        />
        <path
          d="M186.504 205.795a5.176 5.176 0 01-3.283-1.174l-7.268-5.939a5.188 5.188 0 01.126-8.134l30.484-23.38c.752-.58.898-1.656.327-2.415l-18.815-24.875a15.316 15.316 0 011.023-19.73c5.398-5.831 14.366-6.548 20.622-1.65l.119.126 19.647 28.133a17.515 17.515 0 01-.415 27.883l-39.481 30.134a5.2 5.2 0 01-3.088 1.017l.002.004z"
          fill="#CCC"
        />
        <path
          d="M146.538 62.288V48.24l25.082-10.956 23.916 10.952v14.05a2.306 2.306 0 01-2.306 2.305h-44.386a2.306 2.306 0 01-2.306-2.306v.004z"
          fill="#2F2E41"
        />
        <circle fill="#2F2E41" cx={193.077} cy={25.3502806} r={15.722} />
        <path
          d="M178.565 16.916a15.715 15.715 0 0118.8-15.417 15.715 15.715 0 10-9.764 29.63 15.709 15.709 0 01-9.032-14.213h-.004z"
          fill="#2F2E41"
        />
      </g>
    </g>
  </svg>
);

export default LinkingJobs;

/*
<svg viewBox="-100 0 1000 862.87">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M246.81006,165.113715 C246.734122,188.956169 245.120747,212.768912 241.97998,236.403715 C241.87011,237.233715 241.74998,238.063715 241.63964,238.893715 L18.35985,238.893715 C17.69969,238.073715 17.04979,237.243715 16.41991,236.403715 C14.31981,233.623715 12.35008,230.763715 10.54003,227.803715 C1.83983,213.543715 -2.20021,196.813715 1.68993,185.233715 L1.75975,185.053715 C2.59928213,182.518031 3.93141803,180.173152 5.67967,178.153715 C14.63967,167.953715 32.87987,169.543715 49.46967,177.643715 C34.53999,161.483715 22.60981,141.053715 21.60981,122.763715 C20.62981,104.923715 29.88959,92.1237655 39.94965,81.2337155 C40.27973,80.8737155 40.60981,80.5237155 40.93989,80.1737155 C41.10004,79.9937155 41.26997,79.8237155 41.42963,79.6437155 C49.27973,71.3437155 58.43989,63.1537155 71.76997,64.0637655 C86.38959,65.0637655 102.6601,77.2137655 114.10004,91.4137655 C125.54004,105.603765 133.17963,121.753765 140.91987,137.513765 C148.66987,153.263765 157.04975,169.413765 169.41987,182.843765 C152.64987,161.873765 139.08979,137.983765 133.99995,114.703765 C128.91011,91.4237655 133.14985,69.0137655 147.92963,57.9537655 C152.417205,54.6987894 157.619106,52.5659647 163.10004,51.7337655 C163.73969,51.6237655 164.38959,51.5337655 165.04975,51.4537655 C178.77975,49.8138155 195.20991,54.4737655 209.22993,65.7537655 C224.66987,78.1737655 235.42963,96.6537655 240.83002,114.223765 C246.22998,131.793705 246.88964,148.733705 246.81006,165.113715 Z" id="Path" fill="#F2F2F2" fill-rule="nonzero"></path>
            <path d="M309.13964,187.893675 L304.87011,187.893675 C303.509757,187.053715 302.14306,186.223715 300.77002,185.403675 C299.91992,184.873675 299.06002,184.363675 298.1997,183.843675 C283.98974,175.273675 269.693193,167.057009 255.31006,159.193675 C240.93994,151.323675 226.499833,143.820342 211.98974,136.683675 C211.196415,136.343523 210.758996,135.48551 210.94974,134.643675 C210.96494,134.590212 210.988687,134.539558 211.02006,134.493675 C211.21976,134.113675 211.66996,133.953675 212.33006,134.273675 C214.10985,135.153675 215.89989,136.033675 217.67967,136.933675 C232.22997,144.193629 246.720043,151.820295 261.14989,159.813675 C275.56981,167.803675 289.90981,176.150342 304.16989,184.853675 C304.46969,185.033675 304.76999,185.223675 305.06979,185.403675 C306.42968,186.233705 307.77978,187.063725 309.13964,187.893675 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M364.18994,187.893675 L361.5498,187.893675 C360.9497,187.063725 360.35986,186.233675 359.7598,185.403675 C345.660193,165.863675 331.563527,146.323675 317.4698,126.783675 C294.38972,94.7936755 271.31648,62.8036755 248.25008,30.8136755 C248.113443,30.6415625 248.011463,30.4445967 247.94978,30.2336755 C247.75008,29.5236755 248.2701,29.1036755 248.94002,29.1736755 C249.511487,29.2520541 250.021543,29.5726833 250.33992,30.0536755 C263.27986,47.9936755 276.216547,65.9270088 289.14998,83.8536755 C312.10994,115.683675 335.066647,147.510342 358.0201,179.333675 C359.4801,181.353675 360.94002,183.383675 362.39998,185.403675 C363,186.233705 363.60009,187.063725 364.18994,187.893675 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M422.91992,172.233705 C422.81006,176.683705 422.54004,181.063705 422.17968,185.403705 C422.10986,186.233705 422.03986,187.063705 421.96968,187.893705 L419.19968,187.893705 C419.27976,187.063755 419.35984,186.233705 419.42968,185.403705 C419.97998,179.133705 420.35986,172.793705 420.37988,166.283705 C420.352707,148.235365 418.493236,130.236411 414.83007,112.563705 C410.934931,93.5347231 405.390562,74.880967 398.25976,56.8137055 C390.77501,37.7581943 381.776361,19.3326401 371.35009,1.7137055 C371.153925,1.42668376 371.065388,1.07962186 371.10009,0.733705497 C371.20996,-0.0562945025 372.25976,-0.296294503 373.0498,0.453705497 C373.205827,0.600850488 373.340439,0.769157417 373.4497,0.953705497 C374.73974,3.1437055 376.006507,5.34037216 377.25,7.5437055 C387.295974,25.2628968 395.913274,43.7548141 403.02,62.8437055 C409.788855,80.9723265 414.965009,99.6563381 418.48972,118.683705 C421.790294,136.335713 423.274769,154.279285 422.91992,172.233705 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M615.71972,238.403685 L8.71972,238.403685 C3.90565909,238.408069 0,242.309624 0,247.123685 L0,658.443665 C0,663.257729 3.9056568,667.159288 8.71972,667.163665 L615.71972,667.163665 C618.271299,667.163665 620.694681,666.04536 622.35009,664.103665 C622.419328,664.028522 622.482839,663.948298 622.54009,663.863665 C623.060594,663.227246 623.482011,662.515876 623.79009,661.753615 C624.228831,660.705411 624.449788,659.579932 624.449788,658.443615 L624.449788,247.123725 C624.449788,242.306053 620.537396,238.403685 615.71972,238.403685 Z M621.959962,658.443685 C621.959962,659.660284 621.602493,660.85006 620.92968,661.863685 C620.331947,662.755904 619.520762,663.484639 618.56982,663.983685 C617.688845,664.438857 616.711333,664.673696 615.71972,664.673696 L8.71972,664.673696 C5.2807175,664.673696 2.48972,661.882688 2.48972,658.443685 L2.48972,247.123725 C2.48972,243.684703 5.280698,240.893675 8.71972,240.893675 L615.71972,240.893675 C619.162314,240.893675 621.959962,243.681135 621.959962,247.123725 L621.959962,658.443685 Z" id="Shape" fill="#3F3D56" fill-rule="nonzero"></path>
            <rect id="Rectangle" fill="#3F3D56" fill-rule="nonzero" x="1.24265" y="277.352895" width="621.95676" height="2.49281"></rect>
            <circle id="Oval" fill="#3F3D56" fill-rule="nonzero" cx="22.43155" cy="261.099785" r="7.47844"></circle>
            <circle id="Oval" fill="#3F3D56" fill-rule="nonzero" cx="43.93206" cy="261.099785" r="7.47844"></circle>
            <circle id="Oval" fill="#3F3D56" fill-rule="nonzero" cx="65.43257" cy="261.099785" r="7.47844"></circle>
            <path d="M277.9771,394.273525 C270.49776,389.404995 266.77766,378.090005 268.61024,367.742125 C270.44282,357.394245 277.34455,348.481365 285.61793,344.637635 C293.793278,340.95285 303.273681,341.626766 310.84559,346.430945 C318.43259,351.070235 329.16286,353.611355 332.76583,362.893385 C335.5346,370.026385 332.02277,384.510865 328.29636,391.439045 C325.07553,397.427265 318.95508,400.166465 313.20503,401.356555 C300.333513,403.981561 286.956204,400.894977 276.53603,392.895815 L277.9771,394.273525 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <polygon id="Path" fill="#FFB8B8" fill-rule="nonzero" points="274.076 449.233975 268.096 459.543975 267.306 460.903975 266.156 462.903975 248.526 462.903975 249.716 460.903975 252.796 455.713975 259.976 443.593975 268.106 446.843975"></polygon>
            <path d="M247.20605,462.903685 L243.98605,460.903685 L243.58605,460.653685 L263.79605,423.823685 C265.551639,420.603825 268.522335,418.220066 272.04605,417.203685 C274.248458,416.547818 276.58132,416.461798 278.82605,416.953685 C280.135843,417.230252 281.396604,417.702194 282.56605,418.353685 C283.047378,418.617582 283.511628,418.911495 283.95605,419.233685 C285.665131,420.431949 287.069392,422.014311 288.05605,423.853685 C290.447351,428.237893 290.286776,433.571281 287.63605,437.803685 L279.84605,450.253685 L273.18605,460.903685 L271.93605,462.903685 L247.20605,462.903685 L247.20605,462.903685 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <circle id="Oval" fill="#FFB8B8" fill-rule="nonzero" cx="299.13014" cy="382.784155" r="24.56103"></circle>
            <path d="M323.78621,462.903685 L268.57621,462.903685 L268.82621,462.193685 C268.68607,461.763685 268.556023,461.333685 268.43607,460.903685 C268.30619,460.453685 268.19584,459.993685 268.09623,459.543685 C267.199066,455.357535 267.202392,451.028452 268.10599,446.843685 C271.15629,431.863685 283.32621,419.693685 283.32621,419.693685 C283.53621,419.533685 283.74613,419.383685 283.95609,419.233685 C285.766526,417.908142 287.730867,416.806787 289.80619,415.953685 C298.84619,412.203685 308.32619,413.713685 314.69584,415.653685 C316.914227,416.324591 319.082936,417.149941 321.18607,418.123685 C321.925815,418.473685 322.32621,418.693685 322.32621,418.693685 L322.95609,437.903685 L323.71586,460.903685 L323.78621,462.903685 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
            <path d="M349.32625,460.903685 L345.10603,439.443685 L340.84627,417.803735 C340.482707,415.974493 339.032764,414.556481 337.19588,414.233735 L328.27595,412.693735 L319.97615,411.263735 C318.34444,410.969714 316.68432,411.60525 315.66609,412.913735 C315.042322,413.691175 314.700332,414.657002 314.69588,415.653735 C314.69588,416.269379 314.822024,416.878494 315.06599,417.443735 C319.39607,427.543735 319.47615,443.533735 318.37605,460.903735 C318.32625,461.563735 318.28621,462.233735 318.23592,462.903735 L349.72615,462.903735 L349.32625,460.903685 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M289.80623,415.953675 C289.433158,414.475327 288.340951,413.284096 286.900448,412.784447 C285.459946,412.284797 283.864693,412.54386 282.65633,413.473675 L278.82625,416.953675 L263.14607,431.233675 C262.657361,431.672238 262.274396,432.215853 262.02595,432.823675 C260.25595,437.053675 256.80623,445.403675 252.79595,455.713675 C252.14605,457.393675 251.47613,459.123675 250.78619,460.903675 C250.54595,461.563675 250.28619,462.233675 250.02593,462.903675 L275.86627,462.903675 C276.07627,462.233675 276.28619,461.563675 276.49615,460.903675 C277.64605,457.273675 278.76615,453.713675 279.84625,450.253675 C283.00591,440.203675 285.85601,431.073675 288.05625,423.853675 C288.66611,421.853675 289.22275,420.007009 289.72617,418.313675 C289.961106,417.547934 289.988732,416.733578 289.80623,415.953675 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <polygon id="Path" fill="#FFB8B8" fill-rule="nonzero" points="355.126 462.903975 356.796 462.903975 356.566 462.343975 355.956 460.903975 346.546 438.573975 345.106 439.443975 333.546 446.433975 339.476 460.903975 340.296 462.903975 349.726 462.903975"></polygon>
            <path d="M345.58601,420.863715 C342.940603,413.938983 335.303279,410.334319 328.27595,412.693715 C327.843765,412.836958 327.41979,413.003877 327.00595,413.193715 C324.636868,414.250501 322.618067,415.960638 321.18613,418.123715 C320.601702,418.987339 320.121631,419.917151 319.75595,420.893715 C318.445303,424.317366 318.556931,428.122778 320.06601,431.463715 L322.95615,437.903715 L333.29599,460.903715 L334.1959,462.903715 L355.12605,462.903715 L356.56599,462.343715 L360.30623,460.903715 L360.82623,460.703715 L345.58601,420.863715 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M318.4203,354.575435 C320.180362,355.103096 322.075503,354.383925 323.04239,352.821435 C323.944974,351.251701 324.069877,349.352508 323.38065,347.678085 C322.683672,346.028751 321.585229,344.57994 320.18532,343.463545 C318.595608,342.024981 316.660218,341.022952 314.56789,340.555175 C312.452828,340.100241 310.244436,340.58574 308.5152,341.885815 C306.798493,343.243805 306.076369,345.506929 306.68927,347.608255 C307.32516,349.452625 309.04776,350.688255 310.76802,351.608465 C314.181139,353.430581 317.944919,354.500577 321.80614,354.746465 L318.4203,354.575435 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M323.19826,382.050515 C319.24957,382.594915 319.75658,380.585055 318.17509,376.926175 C316.5936,373.267295 314.47671,368.923835 310.50794,368.553235 C307.2555,368.249515 304.57576,370.849635 301.94087,372.780425 C297.24456,376.221785 290.91542,377.906185 285.47151,375.841665 C280.0276,373.777145 276.24469,367.289115 278.31025,361.845605 C279.609948,358.807414 282.11041,356.443548 285.21675,355.316395 C288.299806,354.220296 291.574248,353.765438 294.83933,353.979705 C302.100984,354.211914 309.223181,356.035075 315.70326,359.320515 C320.17546,361.592885 324.52914,364.843805 326.2257,369.564605 C327.92226,374.285405 329.0387,380.919335 324.18757,382.196385 L323.19826,382.050515 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M316.40192,341.590975 C314.45,338.193685 316.06119,333.532135 319.25472,331.262105 C322.44825,328.992075 326.73215,328.769445 330.54301,329.679895 C338.213185,331.612044 344.272543,337.485054 346.44326,345.091155 C348.06035,351.032395 347.09892,357.413655 348.46843,363.416805 C349.943965,369.884727 354.025825,375.455814 359.748315,378.81205 C365.470806,382.168285 372.325554,383.011507 378.69085,381.142225 C382.79279,379.937615 386.96142,377.009015 387.36485,372.752925 C388.235463,382.773181 382.637659,392.237964 373.4372,396.301835 C368.1521,398.506475 362.25674,398.650245 356.53213,398.503315 C352.80145,398.407565 348.98582,398.182965 345.5443,396.739655 C340.733855,394.54587 337.009313,390.507616 335.21085,385.535835 C333.458577,380.588972 332.985427,375.279389 333.83538,370.100635 C334.39852,366.072335 335.51889,362.124015 335.83238,358.068635 C336.14587,354.013255 335.55133,349.683115 333.05121,346.474725 C330.55109,343.266335 325.77535,341.627545 322.22079,343.604725 C320.79226,344.399345 319.63979,345.697435 318.08761,346.210215 C316.53543,346.722995 314.33561,345.821855 314.47686,344.193315 L316.40192,341.590975 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M484.00593,462.903685 L484.19593,460.903685 L489.83606,402.223685 C491.684807,400.776204 493.671038,399.513495 495.76624,398.453685 C507.3761,392.603685 518.5763,395.403685 524.63587,397.823685 C526.241953,398.452417 527.79385,399.211621 529.276,400.093685 L532.5763,460.903685 L532.68616,462.903685 L484.00593,462.903685 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
            <circle id="Oval" fill="#FFB8B8" fill-rule="nonzero" cx="510.41886" cy="362.045395" r="24.56103"></circle>
            <path d="M530.34707,377.021345 C534.080902,373.085974 535.066574,367.284477 532.841974,362.33677 C530.617373,357.389063 525.623551,354.276082 520.20176,354.457285 C516.80549,354.570795 513.60528,355.960715 510.36381,356.980725 C505.451493,358.651814 500.210731,359.123739 495.0789,358.357115 C489.916331,357.555595 485.25769,354.804849 482.06248,350.671425 C479.0237,346.494905 478.07612,340.702025 480.22287,336.004255 C482.70122,330.580825 488.49044,327.592435 493.93655,325.164325 C496.132727,324.064782 498.478601,323.294027 500.89895,322.876775 C503.350102,322.469675 505.860032,323.102381 507.8253,324.622775 C509.35571,325.939055 510.25267,327.917585 511.91805,329.058315 C513.79273,330.342405 516.22795,330.308705 518.48894,330.535035 C528.9164,331.578853 537.610053,338.966007 540.323445,349.08821 C543.036838,359.210412 539.203782,369.955519 530.69655,376.075035 L530.34707,377.021345 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M499.42585,408.613715 L495.76618,398.453715 L495.53766,397.820415 C495.318201,397.212014 494.831125,396.738348 494.216836,396.535956 C493.602546,396.333564 492.929305,396.424937 492.39118,396.783735 L488.23591,399.553735 C488.23591,399.553735 461.586,399.553735 459.44591,415.543735 C459.41612,415.793735 459.37608,416.073735 459.3463,416.373735 C459.344684,416.387468 459.341233,416.400921 459.33604,416.413735 C459.06604,417.463735 454.03624,436.903735 449.71593,460.903735 C449.5963,461.563735 449.47618,462.233735 449.35606,462.903735 L507.26622,462.903735 L507.26622,462.903735 C509.15047,438.794615 508.09627,416.378835 499.42585,408.613715 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M568.50593,460.903685 C565.34627,436.283685 560.82625,416.373685 560.82625,416.373685 C560.78625,416.073685 560.74617,415.793685 560.7159,415.543685 C558.58601,399.553685 531.93611,399.553685 531.93611,399.553685 L527.78328,396.785065 C527.244805,396.426107 526.571117,396.334861 525.956557,396.53765 C525.341997,396.740439 524.854915,397.214708 524.63582,397.823645 L520.74617,408.613645 C512.07576,416.378715 511.02156,438.794245 512.90584,462.902945 L512.90633,462.903615 L568.75593,462.903615 C568.67585,462.233705 568.58601,461.563665 568.50593,460.903685 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <path d="M99.42585,404.843695 L93.48591,460.903695 L93.27591,462.903695 L77.20613,462.903695 C77.39607,462.253695 77.58601,461.583695 77.78621,460.903695 C82.34627,445.263695 88.55621,422.433695 87.88582,416.383695 C86.83601,406.943695 94.18611,403.793695 94.18611,403.793695 L94.64611,403.883695 L99.42585,404.843695 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
            <circle id="Oval" fill="#A0616A" fill-rule="nonzero" cx="125.34263" cy="362.979085" r="24.56103"></circle>
            <path d="M157.13585,451.003665 C155.897335,453.287018 154.882677,455.684859 154.10606,458.163665 C153.81606,459.043665 153.53624,459.963665 153.27598,460.903665 C153.08604,461.553665 152.91612,462.223665 152.7462,462.903665 L87.96585,462.903665 C88.05618,462.243665 88.13577,461.573665 88.2261,460.903665 C91.53616,434.753665 94.21585,408.213665 94.64602,403.883665 C94.68602,403.473665 94.70608,403.263665 94.70608,403.263665 C133.5259,382.803665 161.85598,409.563665 161.85598,409.563665 C161.85598,409.563665 161.89598,409.753665 161.95608,410.123665 C162.57625,413.863715 165.73592,435.703675 157.13585,451.003665 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
            <path d="M154.10603,458.163695 L149.78621,448.903695 L150.83601,412.183695 L161.95613,410.123645 L162.34627,410.053695 C168.093999,413.394959 172.541804,418.583042 174.9659,424.773695 C177.12605,430.173695 181.2359,446.413695 184.70613,460.903695 C184.86629,461.573695 185.02595,462.243695 185.18613,462.903695 L156.31599,462.903695 L155.38582,460.903695 L154.10603,458.163695 Z" id="Path" fill="#991A5F" fill-rule="nonzero"></path>
            <path d="M193.61629,297.903685 L51.59629,297.903685 C45.2536425,297.911267 40.1137643,303.051038 40.10605,309.393685 L40.10605,451.413685 C40.1137643,457.756333 45.2536425,462.896104 51.59629,462.903685 L193.61629,462.903685 C199.960281,462.899537 205.102025,457.757676 205.10603,451.413685 L205.10603,309.393685 C205.10202,303.049697 199.960278,297.907839 193.61629,297.903685 L193.61629,297.903685 Z M203.10603,451.413685 C203.101691,456.652968 198.855572,460.899203 193.61629,460.903685 L51.59629,460.903685 C46.3583432,460.895789 42.1140791,456.651632 42.10605,451.413685 L42.10605,309.393685 C42.1140791,304.155739 46.3583432,299.911582 51.59629,299.903685 L193.61629,299.903685 C198.855572,299.908168 203.101691,304.154403 203.10603,309.393685 L203.10603,451.413685 Z M93.48592,460.903685 L93.27592,462.903685 L152.74614,462.903685 C152.91606,462.223685 153.08598,461.553685 153.27592,460.903685 L93.48592,460.903685 Z" id="Shape" fill="#3F3D56" fill-rule="nonzero"></path>
            <path d="M384.22615,297.903685 L242.20615,297.903685 C235.863506,297.911273 230.723634,303.051042 230.71592,309.393685 L230.71592,451.413685 C230.723634,457.756329 235.863506,462.896098 242.20615,462.903685 L384.22615,462.903685 C390.570142,462.899537 395.71189,457.757678 395.7159,451.413685 L395.7159,309.393685 C395.711884,303.049695 390.57014,297.907839 384.22615,297.903685 L384.22615,297.903685 Z M393.7159,451.413685 C393.711555,456.652969 389.465434,460.899203 384.22615,460.903685 L242.20615,460.903685 C236.968205,460.895789 232.723944,456.65163 232.71592,451.413685 L232.71592,309.393685 C232.723944,304.155741 236.968205,299.911582 242.20615,299.903685 L384.22615,299.903685 C389.465434,299.908168 393.711555,304.154402 393.7159,309.393685 L393.7159,451.413685 Z M268.43611,460.903685 C268.55623,461.333685 268.686277,461.763685 268.82625,462.193685 L268.57625,462.903685 L318.23592,462.903685 C318.28621,462.233685 318.32625,461.563685 318.37605,460.903685 L268.43611,460.903685 Z" id="Shape" fill="#3F3D56" fill-rule="nonzero"></path>
            <path d="M574.84627,297.903685 L432.82627,297.903685 C426.482111,297.907624 421.340101,303.049527 421.33603,309.393685 L421.33603,451.413685 C421.340101,457.757844 426.482111,462.899747 432.82627,462.903685 L574.84627,462.903685 C581.188756,462.895911 586.328379,457.756172 586.33601,451.413685 L586.33601,309.393685 C586.328379,303.051199 581.188756,297.91146 574.84627,297.903685 Z M584.33601,451.413685 C584.328218,456.651535 580.08412,460.89575 574.84627,460.903685 L432.82627,460.903685 C427.586822,460.899407 423.340441,456.653133 423.33603,451.413685 L423.33603,309.393685 C423.340441,304.154238 427.586822,299.907964 432.82627,299.903685 L574.84627,299.903685 C580.08412,299.911621 584.328218,304.155836 584.33601,309.393685 L584.33601,451.413685 Z M507.41609,460.903685 C507.36629,461.563685 507.31599,462.233685 507.26619,462.903685 L550.35603,462.903685 C550.43611,462.243685 550.52595,461.573685 550.60603,460.903685 L507.41609,460.903685 Z" id="Shape" fill="#3F3D56" fill-rule="nonzero"></path>
            <path d="M137.09063,384.377655 C138.81008,386.073336 141.514739,386.246627 143.43653,384.784245 C149.22508,380.493325 154.3069,375.152245 157.31431,368.637495 C160.76038,361.172495 161.14482,352.012135 156.98994,344.917225 C153.4776,338.919515 147.27549,335.049165 141.09411,331.871225 C138.694003,330.516559 136.108709,329.520128 133.42027,328.913555 C130.703047,328.314104 127.859503,328.772916 125.46871,330.196555 C123.075027,331.697391 121.844719,334.511027 122.36812,337.287405 C119.398983,333.899725 115.596029,331.34685 111.33623,329.881835 C108.653841,328.83242 105.71663,328.619488 102.91093,329.271045 C98.98836,330.383655 96.12466,333.868815 94.55837,337.633275 C93.2916902,340.496732 92.8542835,343.658268 93.29593,346.758075 C93.7659367,349.877543 95.5357738,352.651763 98.16654,354.392755 C101.40967,356.399315 105.5471,356.254645 109.27162,355.434865 C112.99614,354.615085 116.60149,353.189285 120.39808,352.828705 C124.19467,352.468125 128.42245,353.414805 130.73301,356.448845 C132.36016,358.585485 132.77801,361.377255 133.14322,364.037985 L135.043,377.877075 C135.198374,379.87904 135.709685,381.837226 136.5529,383.659585 C136.698967,383.92208 136.879848,384.163623 137.09063,384.377655 L137.09063,384.377655 Z" id="Path" fill="#2F2E41" fill-rule="nonzero"></path>
            <g id="Group" transform="translate(95.000000, 492.000000)" fill-rule="nonzero">
                <path d="M39.1839177,74.0588137 C25.1848524,74.0588137 12.2491824,67.0010491 5.24964959,55.5441106 C-1.7498832,44.0871721 -1.7498832,29.9716416 5.24964959,18.5147031 C12.2491824,7.05776459 25.1848524,3.55271368e-14 39.1839177,3.55271368e-14 L395.816083,3.55271368e-14 C417.456763,3.55271368e-14 435,16.5786301 435,37.0294068 C435,57.4801835 417.456763,74.0588137 395.816083,74.0588137 L39.1839177,74.0588137 Z" id="Path_204" fill="#E6E6E6"></path>
                <path d="M39.1839177,4.82992263 C20.3659355,4.82992263 5.11094681,19.2461228 5.11094681,37.0294068 C5.11094681,54.8126909 20.3659355,69.228891 39.1839177,69.228891 L395.816083,69.228891 C414.634066,69.228891 429.889054,54.8126909 429.889054,37.0294068 C429.889054,19.2461228 414.634066,4.82992263 395.816083,4.82992263 L39.1839177,4.82992263 Z" id="Path_205" fill="#FFFFFF"></path>
                <rect id="Rectangle_15" fill="#E6E6E6" x="146.230703" y="4.82910205" width="1.1357657" height="64.3989684"></rect>
                <rect id="Rectangle_17" fill="#E6E6E6" x="287.634401" y="4.82910205" width="1.1357657" height="64.3989684"></rect>
                <rect id="Rectangle_7" fill="#991A5F" x="147.366469" y="4.82910205" width="140.267932" height="64.3989684"></rect>
                <path d="M218,18 C206.40202,18 197,26.7304474 197,37.5 C197,48.2695526 206.40202,57 218,57 C229.59798,57 239,48.2695526 239,37.5 C239,26.7304474 229.59798,18 218,18 L218,18 Z M217.5,23 C221.089851,23 224,25.6862915 224,29 C224,32.3137085 221.089851,35 217.5,35 C213.910149,35 211,32.3137085 211,29 C211.000499,25.6866169 213.910502,23.0008333 217.5,23.0008333 L217.5,23 Z M217.5,52 C212.487349,51.9790119 207.809344,49.6974985 205,45.9036161 C205.099826,42.0958551 213.333333,40 217.5,40 C221.666667,40 229.900174,42.0958551 230,45.9036161 C227.187638,49.6944338 222.511545,51.9750145 217.5,52 L217.5,52 Z" id="Path_198" fill="#FFFFFF"></path>
                <path d="M76,18 C64.4020203,18 55,26.7304474 55,37.5 C55,48.2695526 64.4020203,57 76,57 C87.5979797,57 97,48.2695526 97,37.5 C97,26.7304474 87.5979797,18 76,18 Z M76.5,23 C80.0898509,23 83,25.6862915 83,29 C83,32.3137085 80.0898509,35 76.5,35 C72.9101491,35 70,32.3137085 70,29 C70,25.6862915 72.9101491,23 76.5,23 Z M76.5,52 C71.487349,51.9790119 66.8093436,49.6974985 64,45.9036161 C64.0998264,42.0958551 72.3333333,40 76.5,40 C80.6666667,40 88.9001736,42.0958551 89,45.9036161 C86.1876382,49.6944338 81.5115452,51.9750145 76.5,52 L76.5,52 Z" id="Path_200" fill="#E6E6E6"></path>
                <path d="M359,18 C347.40202,18 338,26.7304474 338,37.5 C338,48.2695526 347.40202,57 359,57 C370.59798,57 380,48.2695526 380,37.5 C380,26.7304474 370.59798,18 359,18 Z M358.5,23 C362.089851,23 365,25.6862915 365,29 C365,32.3137085 362.089851,35 358.5,35 C354.910149,35 352,32.3137085 352,29 C352,25.6862915 354.910149,23 358.5,23 Z M358.5,52 C353.487349,51.9790119 348.809344,49.6974985 346,45.9036161 C346.099826,42.0958551 354.333333,40 358.5,40 C362.666667,40 370.900174,42.0958551 371,45.9036161 C368.187638,49.6944338 363.511545,51.9750145 358.5,52 L358.5,52 Z" id="Path_242" fill="#E6E6E6"></path>
                <rect id="Rectangle_9" fill="#991A5F" x="193.251229" y="101.047627" width="48.6260543" height="45.952373" rx="6"></rect>
                <rect id="Rectangle_12" fill="#E6E6E6" x="334.527284" y="100.899923" width="48.6260543" height="45.952373" rx="6"></rect>
                <rect id="Rectangle_13" fill="#FFFFFF" x="341.473863" y="107.284841" width="35.4258172" height="33.4779449"></rect>
                <polygon id="Path_202" fill="#FFFFFF" points="215.283173 131.15874 209.139793 123.691479 212.712071 121.06563 215.620951 124.599859 225.448624 114.797233 228.735224 117.739006"></polygon>
                <polygon id="Path_203" fill="#E6E6E6" points="356.790202 131.15874 350.646821 123.691479 354.219099 121.06563 357.127979 124.599859 366.954784 114.797233 370.241384 117.739006"></polygon>
                <rect id="Rectangle_18" fill="#E6E6E6" x="51.8475316" y="100.899923" width="48.6260543" height="45.952373" rx="6"></rect>
                <rect id="Rectangle_19" fill="#FFFFFF" x="58.7941108" y="107.284841" width="35.4258172" height="33.4779449"></rect>
                <polygon id="Path_243" fill="#E6E6E6" points="74.1104496 131.15874 67.9670686 123.691479 71.539347 121.06563 74.448227 124.599859 84.2750316 114.797233 87.5616319 117.739006"></polygon>
            </g>
            <g id="Group-2" transform="translate(546.942001, 268.661719)" fill-rule="nonzero">
                <ellipse id="Ellipse_29" fill="#E6E6E6" cx="134.439" cy="461.105281" rx="134.439" ry="18"></ellipse>
                <polygon id="Path_257" fill="#FFB8B8" points="225.067 446.873281 211.887 446.873281 205.619 396.036281 225.071 396.036281"></polygon>
                <path d="M202.467,443.106281 L227.885,443.106281 L227.885,459.106281 L186.467,459.106281 C186.467,450.269725 193.630444,443.106281 202.467,443.106281 Z" id="Path_258" fill="#2F2E41"></path>
                <polygon id="Path_259" fill="#FFB8B8" points="90.417 446.873281 77.237 446.873281 70.969 396.036281 90.421 396.036281"></polygon>
                <path d="M67.823,443.106281 L93.241,443.106281 L93.241,459.106281 L51.819,459.106281 C51.819,450.269725 58.982444,443.106281 67.819,443.106281 L67.823,443.106281 Z" id="Path_260" fill="#2F2E41"></path>
                <path d="M134.439,69.7822806 L134.439,64.0172806 C134.439,43.4833336 151.085053,26.8372806 171.619,26.8372806 L171.619,26.8372806 C192.152947,26.8372806 208.799,43.4833336 208.799,64.0172806 L208.799,69.7812806 C208.799,84.5825119 196.800231,96.5812806 181.999,96.5812806 L161.243,96.5812806 C146.441769,96.5812806 134.443,84.5825119 134.443,69.7812806 L134.439,69.7822806 Z" id="Path_261" fill="#2F2E41"></path>
                <circle id="Ellipse_36" fill="#FFB8B8" cx="171.62" cy="70.2542806" r="28.316"></circle>
                <path d="M17.6,167.567281 C19.9262287,165.371115 23.105673,164.317998 26.2829619,164.691252 C29.4602508,165.064506 32.3090711,166.825796 34.063,169.501281 L58.336,164.910281 L64.724,178.980281 L30.354,184.980281 C25.5592607,187.682046 19.5017431,186.373538 16.2497199,181.933559 C12.9976967,177.493581 13.5776161,171.32354 17.6,167.567281 L17.6,167.567281 Z" id="Path_263" fill="#FFB8B8"></path>
                <path d="M149.544,116.401281 L149.861,116.882281 L110.061,143.103281 L42.897,164.550281 C41.0609108,165.140072 39.8967488,166.944293 40.116,168.860281 L41.581,181.480281 C41.7124388,182.609725 42.3142076,183.631328 43.2383178,184.293855 C44.162428,184.956381 45.3231369,185.198359 46.435,184.960281 L109.647,171.411281 C112.706767,170.759427 115.599938,169.48575 118.147,167.669281 L162.857,135.854281 C167.962674,132.216178 169.213641,125.158998 165.66955,119.987623 C162.125458,114.816248 155.092345,113.436387 149.857,116.885281 L149.544,116.401281 Z" id="Path_264" fill="#CCCCCC"></path>
                <path d="M212.122,432.447281 C210.054389,432.447281 208.182172,431.22547 207.351,429.332281 L146.93,280.032281 C146.641249,279.368904 145.969996,278.955457 145.247642,278.996059 C144.525287,279.036661 143.904594,279.522725 143.692,280.214281 L94.397,425.564281 C93.9763889,426.931447 93.0100708,428.06435 91.726353,428.695324 C90.4426352,429.326297 88.9553894,429.399371 87.616,428.897281 L71.086,422.697281 C69.2562837,422.017443 67.9647591,420.365916 67.746,418.426281 C60.309,353.644281 125.159,190.126281 125.815,188.480281 L125.997,188.025281 L185.113,201.102281 L185.236,201.236281 C208.821,226.966281 228.207,389.248281 231.854,421.519281 C232.133978,423.920134 230.710807,426.193884 228.429,426.991281 L213.838,432.151281 C213.286944,432.346505 212.706616,432.447281 212.122,432.447281 L212.122,432.447281 Z" id="Path_265" fill="#2F2E41"></path>
                <path d="M161.188,206.370281 C146.865,206.370281 130.897,203.514281 125.982,191.728281 L125.869,191.457281 L126.022,191.206281 C129.902,184.840281 135.029,173.982281 132.273,171.943281 C126.844,167.929281 124.209,161.325281 124.443,152.315281 C124.951,132.756281 138.278,115.390281 157.606,109.103281 L157.606,109.103281 C163.000935,107.37663 168.491073,105.962944 174.049,104.869281 C182.253538,103.213993 190.768319,105.316824 197.259,110.601281 C203.831082,115.920499 207.677999,123.901586 207.745,132.356281 C207.945,153.256281 204.73,182.371281 188.245,202.356281 C187.472271,203.283597 186.39719,203.908604 185.209,204.121281 C177.281026,205.556341 169.244574,206.308766 161.188,206.370281 Z" id="Path_266" fill="#CCCCCC"></path>
                <path d="M155.86,205.583281 C157.482347,199.763885 163.488587,196.336528 169.324,197.900281 C169.896328,198.060198 170.454541,198.266894 170.993,198.518281 L189.423,181.745281 L202.241,190.380281 L175.884,213.704281 C173.434508,218.118787 168.294469,220.29916 163.418,218.992281 C160.629469,218.229134 158.261821,216.382727 156.842238,213.864179 C155.422656,211.345631 155.068994,208.364037 155.86,205.583281 L155.86,205.583281 Z" id="Path_267" fill="#FFB8B8"></path>
                <path d="M186.504,205.795281 C185.306799,205.795281 184.146551,205.380651 183.221,204.621281 L175.953,198.682281 C174.725538,197.679135 174.024069,196.169848 174.048622,194.584805 C174.073175,192.999761 174.821056,191.512926 176.079,190.548281 L206.563,167.168281 C207.315191,166.588798 207.461003,165.511928 206.89,164.753281 L188.075,139.878281 C183.56125,133.931066 183.993411,125.59581 189.098,120.147281 L189.098,120.147281 C194.496494,114.316961 203.463608,113.599922 209.72,118.498281 L209.839,118.624281 L229.486,146.757281 C233.792857,150.133915 236.273861,155.32957 236.192416,160.801685 C236.110971,166.2738 233.47644,171.393317 229.071,174.640281 L189.59,204.774281 C188.695823,205.434619 187.613575,205.791046 186.502,205.791281 L186.504,205.795281 Z" id="Path_268" fill="#CCCCCC"></path>
                <path d="M146.538,62.2882806 L146.538,48.2392806 L171.62,37.2832806 L195.536,48.2352806 L195.536,62.2842806 C195.536,62.8958698 195.293047,63.4824099 194.860588,63.9148688 C194.428129,64.3473277 193.841589,64.5902806 193.23,64.5902806 L148.844,64.5902806 C148.232411,64.5902806 147.645871,64.3473277 147.213412,63.9148688 C146.780953,63.4824099 146.538,62.8958698 146.538,62.2842806 L146.538,62.2882806 Z" id="Path_269" fill="#2F2E41"></path>
                <circle id="Ellipse_30" fill="#2F2E41" cx="193.077" cy="25.3502806" r="15.722"></circle>
                <path d="M178.565,16.9162806 C178.562668,12.20627 180.673046,7.74354954 184.315051,4.7569118 C187.957056,1.77027407 192.746636,0.574661204 197.365,1.49928057 C191.870731,-1.08959597 185.3765,-0.280637389 180.685246,3.57700625 C175.993992,7.43464989 173.945685,13.6502665 175.424384,19.5411687 C176.903082,25.4320708 181.644025,29.943491 187.601,31.1282806 C182.091084,28.5409852 178.571854,23.0034198 178.569,16.9162806 L178.565,16.9162806 Z" id="Path_185" fill="#2F2E41"></path>
            </g>
        </g>
    </svg>
*/
