import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import Logo from './Logo';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto px-2">
      <Logo />
      <div className="flex self-stretch items-center justify-around sm:space-x-6 md:space-x-10 lg:space-x-16 my-4 sm:my-0">
        <AnchorLink offset="20" href="#features">
          Features
        </AnchorLink>
        <AnchorLink offset="20" href="#pricing">
          Preise
        </AnchorLink>
        
        <Link
          className="px-4"
          offset="20"
          href="https://morrowmed.notion.site/Willkommen-beim-JiM-Support-b2370c9c8648415a8781154454431ac3"
        >
          HowTo
        </Link>
        <AnchorLink offset="20" href="#contact">
          Kontakt
        </AnchorLink>
        <div className="md:hidden">
          <a href="https://jim.morrowmed.de">
            <Button className="text-sm" size="sm">
              Login
            </Button>
          </a>
        </div>
      </div>
      <div className="hidden md:block">
        <a href="https://jim.morrowmed.de">
          <Button className="text-sm">Login</Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
